import React from 'react'

export default function Testimonial() {
  return (
    <div>
        <div style={{background: "#fafafa"}}>
        <div className="careerz mb-0">
            <div className="goorev ppcad corporate" id="testimonials" >
    
                <div className="box2 pb-40">
    
                    <h3 >Testimonials - Our Clients Speak</h3>
                    <div className="testz embed">
        
                        <div className="itez">
                            <div className="item">
                                <div className="rev">
                                    <p>"Trainer was very knowledgeable and was able to tailor the whole day to our individual needs of some major digital marketing techniques in the program covered in the workshop. Needless to say, your expertise was a massive guidance. Very informative, made us think about possible scenarios, do’s and don’ts in the digital arena. The experience with the workshop from start to finish was faultless. I would recommend this workshop program to my colleagues, contacts as well."
                                    </p>
                                </div>
                                <div className="person" style={{height:"100px"}}>
                                    <img width="85" loading="lazy" src={require("../images_fl/l&t-realty.jpg")} alt="Digital Marketing Training - L&T Realty"/>
                                    <span style={{fontSize:"17px"}}>DIGITAL MARKETING & COMMUNICATION <br/> L&T REALTY</span>
                                </div>
                            </div>
                        </div>
                        
                        <div className="itez">
                            <div className="item">
                                <iframe width="100%" height="300" src="https://www.youtube.com/embed/3JvmLgG0zGI?controls=0" title='Video Testimonial'>
                                </iframe>
                            </div>
                        </div>
                        
                        <div className="itez">
                            <div className="item">
                                <div className="rev">
                                    <p>"The 2-Day Digital Marketing workshop was very insightful and knowledgeable. The way trainers imparted the training, I will definitely recommend TBS to others."
                                    </p>
                                </div>
                                <div className="person" style={{height:"100px"}}>
                                    <img width="85" loading="lazy" src={require("../images_fl/nism.jpg")} alt="Digital Marketing Workshop - NISM"/>
                                    <span style={{fontSize:"17px"}}>NATIONAL INSTITUTE OF SECURITIES <br/> MARKETS</span>
                                </div>
                            </div>
                        </div>
         
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}
