import React from 'react'

export default function Contact() {
  return (
    <div>
     <div className="careerz">
        <div className="enquiryz-box">
            <div className="bg-img"></div>
            <div className="apply-form">
                <p>Apply Now</p>
                <span>And we'll get back to you within 48 hours.</span>
                <form action="" id="" name="" method="post" role="form" onsubmit="redirect();">
                    <label for="name">Full name</label>
                    <input type="text" id="name" name="name" placeholder="Your Full Name" required/>
                    <label for="email">Email <small>*</small></label>
                    <input type="email" id="email" name="email" value="" placeholder="Your Email" className="required email sm-form-control"/>
                    <label for="city">City <small>*</small></label>
                    <input type="text" name="city" id="city" value="" placeholder="Your City" size="22" tabindex="5" className="sm-form-control required"/>
                    <label for="service">Position <small>*</small></label>
                        <select name="position" id="position" tabindex="9" className="sm-form-control required">
                            <option value="">-- Select Position --</option>                       
                            <option value="Faculty">Faculty</option>
                            <option value="Training Coordinator">Training Coordinator</option>
                            <option value="Counselor">Counselor</option>
                        </select>
                    <label for="experience">Experience (optional)</label>
                    <textarea name="experience" id="experience" rows="3" tabindex="10" className="sm-form-control"></textarea>
                    <label for="application">Message <small>*</small></label>
                    <textarea name="application" id="application" rows="6" tabindex="11" className="sm-form-control required"></textarea>
                    <button type="submit" id="submit" name="submit">Send</button>
                </form>
            </div>
        </div>
    </div>
    </div>
  )
}
