import '../App.css'
import Navbar from '../components/Navbar/Navbar';
import Company from '../components/Companies_charni/Companies';
import Footer from '../components/Footer/Footer';
import Head from '../components/Careers/Head';
import Contact from '../components/Careers/Contact';
import Career from '../components/Careers/Career';


function Careers() {
  return (
    <div>
      <Navbar/>
      <Head/>
      <Company/>
      <Career/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default Careers;
