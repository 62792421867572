import React from 'react'
import image from '../images_fl/awards-hero.jpg';

export default function Head() {
  return (
    <div>
        <div className="aboutzz-hero-con">
        <div className="aboutzz-hero">
            <div className="about-text">
                <h1>Awards</h1>
            </div>
        <div className="ab-img-box">
            <div className="imgs">
                <div className="bg-img" style={{backgroundImage: `url(${image})`}}></div>
                <div className="image-box-pattern">
                    <div className="img-pattern"></div>
                </div>
                <div className="img-circle-pattern"></div>
            </div>
        </div>
        </div>
    </div>
    </div>
  )
}
