import React from 'react'

export default function Head() {
  return (
    <div>
        <div className="thankyou-content seoz">
        <div className="container">
            <div className="text-con">
                <div className="ad-text">
                    <h1 style={{color:"White"}}>Certification Course in SEO with placement.</h1>
                    <h2 className="sub-header" style={{letterSpacing: "1px", lineHeight: "24px" }}>Learn Search Engine Optimisation from <strong>IIEDM</strong>, the<strong> top SEO training institute in Mumbai</strong> providing the best SEO courses with placements.
                    </h2>
                    <ul>
                        <li>Get trained and mentored by <strong>Industry Expert SEO Professionals</strong></li>
                        <li><strong>100% Job Assistance and certification </strong> (Internship/Full Time)
                        </li>
                        <li>Practical training with <strong>Live Projects in SEO</strong></li>
                        <li>Be Job ready with our <strong>Interview Preparation Sessions</strong></li>
                    </ul>
                    <a href="#contact" className="blue-button">Request a Brochure</a>
                    <a href="#syllabus" className="blue-button syl">Syllabus</a>
                </div>

            </div>
            <div className="bg-img"></div>
        </div>
    </div>
    </div>
  )
}
