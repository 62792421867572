import react from 'react'

const Navbar = ()=>{
    return(
    <div>
    
    <nav id="main-navbar">
      <div id="logo">
        <a href="/index.php" title="IIEDM Digital Marketing Institute">
        <img src={require("../images_fl/logo-v2.png")} alt="IIEDM Digital Marketing Institute Logo"/>
        </a>
      </div>
     <label for="drop" className="toggle ham">
        <span className="hamburger">
          <span></span>
          <span></span>
          <span></span>
        </span>
       
      </label>
       <input type="checkbox" id="drop" />
      <ul className="menu">
        <li><a href="/" title="IIEDM's homepage">Home</a></li>
        <li>
     
          <label for="drop-1" className="toggle">Courses</label>
          <a href="/best-digital-marketing-courses-iiedm-tbs" title="Courses offered by IIEDM">Courses</a>
          <input type="checkbox" id="drop-1" />
         <ul className="sub-menu"> 
            <li><a href="/best-digital-marketing-courses-iiedm-tbs"
                title="Digital marketing Courses offered by IIEDM ">Digital Marketing Courses</a></li>
            <li><a href="/ui-ux-design-course-mumbai" title="UI/UX courses offered by IIEDM">UI/UX Design
                courses</a></li>
            <li>
              <label for="drop-2" className="toggle">Certification Courses</label>
              <a href="#" title="Certification Courses offered by IIEDM">Certification Courses</a>
              <input type="checkbox" id="drop-2" />
              <ul>
                <li><a href="/certification-course-in-seo-mumbai" title="Certification course in SEO">Certification
                    course in SEO</a></li>
                <li><a href="/certification-course-in-social-media-marketing-mumbai"
                    title="Certification course in Social Media Marketing">Certification course in Social Media
                    Marketing</a></li>
                <li><a href="/certification-course-in-ppc-adwords-sem-mumbai"
                    title="Certification course in PPC-Adwords-SEM">Certification course in PPC-Adwords-SEM</a></li>
                <li><a href="/certification-course-content-writing-mumbai"
                    title="Certification course in Content Writing">Certification course in Content Writing</a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
       <li><a href="/digital-marketing-training-student-testimonials"
            title="IIEDM's Students' Testimonials">Testimonials</a></li>
        <li><a href="/digital-marketing-corporate-training"
            title="IIEDM's Inhouse Digital Marketing training for Corporates">Corporate Training</a></li>
        <li><a href="/digital-marketing-training-franchise-in-education"
            title="IIEDM's Educational Franchise Opportunities in India">Franchise</a></li>
        <li>
          
          <label for="drop-3" className="toggle">Centers</label>
          <a href="#" title="IIEDM's Educational Centers">Centers</a>
          <input type="checkbox" id="drop-3" />
          <ul className="sub-menu">
            <li><a href="/digital-marketing-training-institute-dadar-mumbai"
                title="Digital Marketing Institute in Dadar">Dadar</a></li>
            <li><a href="/digital-marketing-training-institute-malad-mumbai"
                title="Digital Marketing Institute in Malad">Malad</a></li>
            <li><a href="/digital-marketing-training-institute-charni-road-mumbai"
                title="Digital Marketing Institute in Charni Road">Charni Road</a></li>
            <li><a href="/digital-marketing-training-institute-dombivli-mumbai"
                title="Digital Marketing Institute in Dombivli">Dombivli</a></li>
            <li><a href="/digital-marketing-training-institute-thailand-pataya-bangkok"
                title="Digital Marketing Institute in Thailand">Thailand</a></li>
          </ul>

        </li>
         <li><a href="/blog/" title="IIEDM's Blog section">Blog</a></li>
       
        <li>
          
          <label for="drop-4" className="toggle">++</label>
          <a href="#" style={{fontSize:'18px'}} title="Know More">++</a>
          <input type="checkbox" id="drop-4" />
          <ul className="sub-menu">
            <li><a href="/about-iiedm" title="Know more about us">About Us</a></li>
            <li><a href="/award-winning-digital-marketing-institute" title="Awards & Recognition">Awards</a></li>
            <li><a href="careers" title="Careers">Careers</a></li>
            <li><a href="/contact-us" title="Contact-Us">Contact Us</a></li>
          </ul>

        </li>
        <li>
          <a title="Call Us Today!" className="enquire-btn" href="tel:7738244304"><img src={require("../images_fl/call-icon.webp")}
              width="20" height="auto" alt="Call us to Enquire Now"/>+91 7738244304</a>
        </li>
      </ul>
    </nav> 
  </div>
    );
};

export default Navbar;