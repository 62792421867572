import React from 'react'

export default function Questions() {
  return (
    <div>
        <div className="FAQ-section">
    <div className="col">
      <p className="head">Frequently Asked Questions</p>
      <div className="tabs">
        <div className="tab">
          <input type="radio" id="rd1" name="rd"/>
          <label className="tab-label" for="rd1">Why should I invest in a Digital Marketing  Education Business Franchise?</label>
          <div className="tab-content">
            <p>Digital Marketing today has become one of the most trending career opportunities for a plethora of individuals, which is why there is a boom in the number of digital coaching institutes. Right from students to individuals who want to learn and ace it. Digital Marketing Education Business Franchise in Mumbai and the rest of India is a wise investment today because of the huge popularity a digital coaching institute garners. Everyone wants to jump on the digital bandwagon and you can assure to provide this opportunity through a digital marketing education business franchise business in Mumbai, India, like IIEDM, the prominent coaching institute, that gets you high returns at low risks.</p><br/>
            <p>IIEDM- a leading coaching institute offers you low cost digital marketing education business franchise opportunities in Mumbai, India that helps you gain access to a favorable business that may help you yield the best results in the long run.</p>
          </div>
        </div>
        <div className="tab">
          <input type="radio" id="rd2" name="rd"/>
          <label className="tab-label" for="rd2">Why should I get into a partnership with IIEDM?</label>
          <div className="tab-content">
            <p>The IIEDM digital marketing coaching institute in Mumbai, India, has been the maestro of the industry for several years and has a concoction of the best of everything- faculty, placements, syllabus and an extensive list of aspirants. This makes it one of the lucrative digital marketing education business franchise opportunities in Mumbai, India. Today, you can find several education business franchise opportunities in Mumbai and all over India, however, it’s imperative to select the one that offers you nothing but the best. Someone like IIEDM!</p><br/>
            <p>IIEDM- a reputable coaching institute offers you a lucrative digital marketing education franchise business opportunity in Mumbai, India that makes your venture in the industry a profitable one.</p>
          </div>
        </div>
        <div className="tab">
          <input type="radio" id="rd3" name="rd"/>
          <label className="tab-label" for="rd3">What are the qualities that IIEDM looks out for in a Business Partner?</label>
          <div className="tab-content">
            <p>At IIEDM, we always have our binoculars for people who are extremely dedicated and have a zeal to grow. With the required education degree and work experience, we partner with those individuals that are on the lookout for low-cost education business franchise opportunities in Mumbai, India, to be and create a change.</p><br/>
            <p>At IIEDM- a credible digital marketing coaching institute in Mumbai, India, we open doors to profitable digital marketing education franchise business opportunities for individuals who wish to offer the necessary knowledge to young and trained digital enthusiasts.</p>
          </div>
        </div>
        <div className="tab">
          <input type="radio" id="rd4" name="rd"/>
          <label className="tab-label" for="rd4"> Is the cost of setting up an education franchise with IIEDM high?
           </label>
          <div className="tab-content">
            <p>IIEDM, the leading digital coaching institute in Mumbai, India, offers one of the finest digital marketing education business franchise opportunities in Mumbai and throughout India. The cost of setting up a business franchise with us at IIEDM is comparatively low considering the high ROI you get throughout. Each penny you invest is utilized to the maximum potential for you to get high returns. We give you the chance of opening a successful coaching institute franchise that helps you avail profits and also aids in providing important knowledge to the aspirants.</p>
          </div>
        </div>
        <div className="tab">
          <input type="radio" id="rd5" name="rd"/>
          <label className="tab-label" for="rd5"> Do you offer support to set up the franchise? Also, how affordable are your courses?</label>
          <div className="tab-content">
            <p>At IIEDM, we don’t just offer the finest digital marketing education franchise business opportunity in Mumbai, India but also dedicatedly help you throughout the process. The IIEDM digital marketing coaching institute has set reasonable and affordable prices for the courses that give an opportunity to everyone- from students to professionals to join the institute.</p>
          </div>
        </div>
        

      </div>
    </div>

  </div>
    </div>
  )
}
