import React from 'react'

export default function Head() {
  return (
    <div>
        <div className="thankyou social">
        <div className="container">
            <div className="text-con">
                <div className="ad-text">
                    <h1>Certification Course in Social Media Marketing</h1>
                    <h1 className="sub-header">Learn Social media marketing from <strong>IIEDM</strong>, the top <strong>Social Media Marketing training institute in Mumbai</strong> providing the best Social media courses with placements.
                    </h1>
                    <ul>
                        <li>Get trained and mentored by<strong> Industry Expert Professionals</strong></li>
                        <li><strong>100% Job Assistance & Lifetime Learning</strong> with our LMS tool</li>
                        <li>Practical training with <strong>Live Projects in Social Media Marketing</strong></li>
                        <li>Be Job ready with our <strong>Interview Preparation Sessions</strong></li>
                    </ul>
                    <a href="#contact" className="blue-button">Request a Brochure</a>
                    <a href="#syllabus" className="blue-button syl">Syllabus</a>
                </div>

            </div>
            <div className="bg-img"></div>
        </div>
    </div>
    </div>
  )
}
