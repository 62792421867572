import react from "react";

const Company = () => {
  return (
    <div>
       <div className="alumini-banner">
          <p style={{fontSize:"17px"}}>Past students of TBS - Digital Marketing Institute have worked with some of the world's leading brands and companies.</p>
          <img loading="lazy" src={require("../images_fl/past-students.webp")} alt="IIEDM Alumini Placement Companies"/>
      </div>
    </div>
  );
};

export default Company;
