import React from 'react'

export default function Mid2() {
  return (
    <div>
        <div class="ad-modules content-hero">
        <div class="header">
            <p style={{fontSize: "30px", fontWeight:"bold"}}>Offering the Best UI/UX Course in Mumbai</p>
            <p>With IIEDM, learn from the best of the industry and get the best of career opportunities through our UI UX design course in Mumbai that surpasses the ordinary</p>
        </div>
        <div class="module-boxes">
            <div class="apdm-box">
                <div class="title">
                    <h3>UI UX Design course that sets you apart from the Rest.</h3>
                </div>
                <div class="content" style={{fontSize:"17px"}}>
                    <p>At IIEDM, an Award-Winning Institute in Mumbai, we firmly believe that 'Design is not just an appearance but it's an experience, an art' and we celebrate this art with our dynamic <strong>UI UX design course</strong>.  UI UX design course. With an expansive curriculum, the best tools and practical experience, we equip you with the design skills that bring out the real artist in you. With us, you can become the Van Gogh of the digital realm where you aren't restricted by the chains of 'norms' but are given wings to design the extraordinary.
                    </p>
                    <p>We're home to the best faculty of the industry that gives you a 360-degree view of UI UX and helps you understand it to the T. With our distinct <strong>UI UX training</strong> we lay the building blocks of expertise, skills and knowledge for you to create a user experience smooth as Butter.
                    </p>
                    <p>Being a prominent <strong>UI UX design</strong>  institute in Mumbai, we don't introduce you to just the concept superficially but instead prep you by giving in-depth knowledge of research, tools and strategies. This aids in creating an interface that needs no guidance and a design that people just Can't take their eyes off. Our expertise is supplemented with the experience of the renowned digital marketing agency-The Brand Saloon, which allows us to offer you placements that pave your way to the best of the industry and gives you a career that you've always desired.

<p>Tags:</p>
<h4>UI/UX courses in Mumbai, Best UI/UX courses in Mumbai, Top UI UX design courses, UIUX courses with placements</h4>
                    </p>
                </div>
                <div class="tags">
                    <a class="blue-button" href="#contact">Request a Brochure</a>
                </div>
            </div>
            <div id="syllabus" class="modules-box">
                <div class="title">
                    <p>Modules Covered</p>
                </div>
                <div class="ad-accordian">

                    <div class="tabs">
                        <div class="tab">
                            <input checked type="radio" id="rd1" name="rd"/>
                            <label class="tab-label" for="rd1">
                                <p>Welcome & Introduction</p>
                            </label>
                            <div class="tab-content">
                                <ul>
                                    <li>Beginners guide to user experience</li>
                                    <li>Super powers of UX</li>
                                    <li>Affordance, signifiers & metaphors</li>
                                    <li>Human-centered design</li>
                                    <li>B2B vs B2C products</li>
                                </ul>
                            </div>
                        </div>
                        <div class="tab">
                            <input type="radio" id="rd2" name="rd"/>
                            <label class="tab-label" for="rd2">
                                <p>Discovery</p>
                            </label>
                            <div class="tab-content">
                                <p>User Research : </p>
                                <ul>
                                    <li>Stakeholder/user Interviews</li>
                                    <li>Contextual Inquiry</li>
                                    <li>Surveys</li>
                                    <li>Competitor Analysis</li>
                                    <li>Design Strategy</li>
                                    <li>Persona Creation</li>
                                </ul>
                            </div>
                        </div>
                        <div class="tab">
                            <input type="radio" id="rd3" name="rd"/>
                            <label class="tab-label" for="rd3">
                                <p>Ideation</p>
                            </label>
                            <div class="tab-content">
                                <ul>
                                    <li>Scenarios</li>
                                    <li>Empathy Map</li>
                                    <li>Experience Map</li>
                                    <li>Exploring Business Opportunities</li>
                                </ul>
                            </div>
                        </div>
                        <div class="tab">
                            <input type="radio" id="rd4" name="rd"/>
                            <label class="tab-label" for="rd4">
                                <p>Design</p>
                            </label>
                            <div class="tab-content">
                                <ul>
                                    <li>Mental Models</li>
                                    <li>What is Card Sorting?</li>
                                    <li>Site-map</li>
                                    <li>Information Architecture</li>
                                    <li>Taxonomy</li>
                                    <li>User Journey & Task flows</li>
                                    <li>Wireframing</li>
                                </ul>
                            </div>
                        </div>
                        <div class="tab">
                            <input type="radio" id="rd5" name="rd"/>
                            <label class="tab-label" for="rd5">
                                <p>UX and Visual Design</p>
                            </label>
                            <div class="tab-content">
                                <ul>
                                    <li>UX Writing</li>
                                    <li>Mood-Boards</li>
                                    <li>Basics of Visual Design</li>
                                    <li>Usability Testing</li>
                                    <li>CRUD Functions</li>
                                </ul>
                            </div>
                        </div>
                        <div class="tab">
                            <input type="radio" id="rd6" name="rd"/>
                            <label class="tab-label" for="rd6">
                                <p>Visual Design - Guidelines</p>
                            </label>
                            <div class="tab-content">
                                <ul>
                                    <li>Design Libraries (Atomic Structure)</li>
                                    <li>Material Design Guidelines</li>
                                </ul>
                            </div>
                        </div>
                        <div class="tab">
                            <input type="radio" id="rd7" name="rd"/>
                            <label class="tab-label" for="rd7">
                                <p>Visual Design and Tools - 1</p>
                            </label>
                            <div class="tab-content">
                                <ul>
                                    <li>IOS Design Guidelines</li>
                                    <li>UI Kits</li>
                                    <li>Introduction to Tools</li>
                                    <li>1.Figma 2.Sketch</li>
                                </ul>
                            </div>
                        </div>
                        <div class="tab">
                            <input type="radio" id="rd8" name="rd"/>
                            <label class="tab-label" for="rd8">
                                <p>Visual Design and Tools - 2</p>
                            </label>
                            <div class="tab-content">
                                <ul>
                                    <li>Axure RP/Balsamiq</li>
                                    <li>Zeplin</li>
                                    <li>Invision</li>
                                </ul>
                            </div>
                        </div>
                        <div class="tab">
                            <input type="radio" id="rd9" name="rd"/>
                            <label class="tab-label" for="rd9">
                                <p>Color Theory & Typography</p>
                            </label>
                            <div class="tab-content">
                                <ul>
                                    <li>Typography</li>
                                    <li>Resources for colors and typography</li>
                                    <li>Visual Design Strategy</li>
                                </ul>
                            </div>
                        </div>
                        <div class="tab">
                            <input type="radio" id="rd10" name="rd"/>
                            <label class="tab-label" for="rd10">
                                <p>Prototyping & Interactions</p>
                            </label>
                            <div class="tab-content">
                                <ul>
                                    <li>Converting wirefires to VD</li>
                                    <li>Interactions and Micro-Interactions</li>
                                    <li>Assets</li>
                                    <li>Jargons for Interaction with Developers</li>
                                </ul>
                            </div>
                        </div>
                        <div class="tab">
                            <input type="radio" id="rd11" name="rd"/>
                            <label class="tab-label" for="rd11">
                                <p>Android Practice</p>
                            </label>
                            <div class="tab-content">
                                <ul>
                                    <li>Static and Dynamic</li>
                                    <li>Responsive & Adaptive</li>
                                    <li>Responsive Design Practices</li>
                                    <li>Review Sessions</li>
                                </ul>
                            </div>
                        </div>
                        <div class="tab">
                            <input type="radio" id="rd12" name="rd"/>
                            <label class="tab-label" for="rd12">
                                <p>Portfolio Design</p>
                            </label>
                            <div class="tab-content">
                                <ul>
                                    <li>Problem Statement Definition</li>
                                    <li>Expectations from the Portfolio</li>
                                    <li>Q&A</li>
                                </ul>
                            </div>
                        </div>
                        <div class="tab">
                            <input type="radio" id="rd13" name="rd"/>
                            <label class="tab-label" for="rd13">
                                <p>Portfolio Progress</p>
                            </label>
                            <div class="tab-content">
                                <ul>
                                    <li>Portfolio Presentation</li>
                                    <li>Interview Presentation</li>
                                    <li>Mock Interview</li>
                                    <li>Certificate and Feedback</li>
                                </ul>
                            </div>
                        </div>
                        <div class="tab">
                            <input type="radio" id="rd14" name="rd"/>
                            <label for="rd14" class="tab-close">Close others &times;</label>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    </div>
  )
}
