import '../App.css'
import Navbar from '../components/Navbar/Navbar';
import Company from '../components/Companies_charni/Companies';
import Footer from '../components/Footer/Footer';
import Head from '../components/Franchise/Head';
import Mid from '../components/Franchise/Mid';
import Contact from '../components/Franchise/Contact';
import Benefits from '../components/Franchise/Benefits';
import Call from '../components/Franchise/Call';
import Testimonials from '../components/Testimonials/Testimonials';
import Questions from '../components/Franchise/Questions';


function Franchise() {
  return (
    <div>
      <Navbar/>
      <Head/>
      <Company/>
      <Mid/>
      <Contact/>
      <Benefits/>
      <Call/>
      <Testimonials/>
      <Questions/>
      <Footer/>
    </div>
  );
}

export default Franchise;
