import react from "react";

const Mid = (props) => {
  return (
    <div>
      <div id="whyus" className="row second-fold">
        <div className="secfold-box secondfold-img">
          <img 
            // style={{height: "600px", paddingTop: "35px"}}
            loading="lazy"
            src={require("../images_fl/top-digital-marketing-institute.webp")}
            alt="Top Digital Marketing Institute in Mumbai"
          />
        </div>
        <div className="secfold-box standout">
          <div className="standout-bg">
            <p>What makes us standout from other Institutes ? </p>
            <ul>
              <li style={{ fontSize: "20px" }}>
                - Award-Winning Institute in Mumbai
              </li>
              <li style={{ fontSize: "20px" }}>
                - Experts of the industry as faculty
              </li>
              <li style={{ fontSize: "20px" }}>
                - Backed by Google Partner Agency
              </li>
              <li style={{ fontSize: "20px" }}>
                - 100% Job assistance and excellent training
              </li>
              <li style={{ fontSize: "20px" }}>
                - Digital library & PDP program
              </li>
              <li style={{ fontSize: "20px" }}>
                - Job portal and interview prepration
              </li>
              <li style={{ fontSize: "20px" }}>
                - Hands-on experience with the live projects and case studies
              </li>
              <li style={{ fontSize: "20px" }}>
                - International presence & Knowledge partners
              </li>
            </ul>
          </div>
        </div>
        <div id="contact" className="secfold-box form-con">
          <div className="form-bg">
          <a
                  href="https://zenoxerp.com/Registration?brk=d4c2235c-52d0-47cc-b272-f431747ccf96&hidelogo=1&hideheader=1"
                  target="_blank"
                >
                  <img
                    loading="lazy"
                    width="480px"
                    height="520px"
                    src={require("../images_fl/3.jpg")}
                    alt="Contact us now!"
                  />
                </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mid;
