import React from 'react'
import image from '../images_fl/franchise-hero.jpg';

export default function Head() {
  return (
    <div>
        <div className="aboutzz-hero-con">
        <div className="aboutzz-hero">
            <div className="about-text">
                <h1 className="large" style={{letterSpacing:"0.5px"}}>IIEDM Franchise- A Step Towards Profitable Business</h1>
                <span>Acquire an Enriching Business Opportunity to Rise with a Digital Marketing Education Franchise.</span>
                <div className="btn-hold"><a href="#enquiry">Enquire Now</a></div>
            </div>
        <div className="ab-img-box">
            <div className="imgs">
                <div className="bg-img" style={{backgroundImage: `url(${image})`}}></div>
                <div className="image-box-pattern">
                    <div className="img-pattern"></div>
                </div>
                <div className="img-circle-pattern"></div>
            </div>
        </div>
        </div>
    </div>
    </div>
  )
}
