import '../App.css'
import Navbar from '../components/Navbar/Navbar';
import Header from '../components/Header_charni/Header';
import Company from '../components/Companies_charni/Companies';
import Mid from '../components/Mid/Mid';
import Course from '../components/Courses_charni/Courses';
import Testimonials from '../components/Testimonials/Testimonials';
import Media from '../components/Media/Media';
import Map from '../components/Map/Map';
import Question from '../components/Questions_charni/Questions';
import Footer from '../components/Footer/Footer';
import Call from '../components/Call/Call';
import Award from '../components/Award/Award';

function Charni() {
  return (
    <div>
      <Navbar/>
      <Header location = "Charni Road" phone="8928272418"/>
      <Company/>
      <Course location = "Charni Road"/>
      <Call text1 = "South Mumbai! Digital Marketing is the new calling!" text2="An exciting opportunity awaits digital marketing fanatics in South Mumbai - Churchgate, Charni Road, Fort." phone="8928272418"/>
      <Mid phone="8928272418"/>
      <Award/>
      <Testimonials/>
      <Media/>
      <Map link="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60374.90909053033!2d72.78120677910154!3d18.9565275!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cf4d472abca3%3A0xfa2c15422daf0a46!2sIIEDM%20-%20Digital%20Marketing%20Courses%20Training%20Institute%20Charni%20Road!5e0!3m2!1sen!2sin!4v1623408054482!5m2!1sen!2sin"/>
      <Question location="Charni Road"/>
      <Footer/>
    </div>
  );
}

export default Charni;
