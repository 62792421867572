import React from 'react'

export default function Head() {
  return (
    <div>
        <div className="thankyou ppcad">
        <div className="container">
            <div className="text-con">
                <div className="ad-text">
                    <h1 style={{color: "white"}}>Certification Course in SEM / Google AdWords</h1>
                    <h1 className="sub-header">IIEDM provides the best Search Engine Marketing (SEM), Pay Per Click (PPC), and Google Ads certificate course and training in Mumbai with placement.
                    </h1>
                    <ul>
                        <li>Get trained and mentored by<strong> Google Certified Professionals
                        </strong></li>
                        <li><strong>100% Job Assistance & Lifetime Learning </strong>with our LMS tool
                        </li>
                        <li><strong>International Presence & Knowledge Partners</strong></li>
                        <li><strong>Assistance in Clearing Google Certification
                        </strong></li>
                    </ul>
                    <a href="#contact" className="blue-button">Request a Brochure</a>
                    <a href="#syllabus" className="blue-button syl">Syllabus</a>
                </div>

            </div>
            <div className="bg-img"></div>
        </div>
    </div>
    </div>
  )
}
