import '../App.css'
import Navbar from '../components/Navbar/Navbar';
import Company from '../components/Companies_charni/Companies';
import Footer from '../components/Footer/Footer';
import Mid from '../components/Mid/Mid';
import Award from '../components/Award/Award';
import Testimonials from '../components/Testimonials/Testimonials';
import Centers from '../components/Courses_SEO/Centers';
import Head from '../components/Courses_SocialMedia/Head';
import Mid2 from '../components/Courses_SocialMedia/Mid2';
import Programs from '../components/Courses_SocialMedia/Programs';
import Questions from '../components/Courses_SocialMedia/Questions';


function Courses_SocialMedia() {
  return (
    <div>
      <Navbar/>
      <Head/>
      <Company/>
      <Mid phone="7738244304"/>
      <Mid2/>
      <Award/>
      <Testimonials/>
      <Programs/>
      <Questions/>
      <Centers/>
      <Footer/>
    </div>
  );
}

export default Courses_SocialMedia;
