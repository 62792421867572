import react from "react";

const Company = () => {
  return (
    <div>
      <div className="alumini-banner">
        <p>
          Past students of IIEDM institute, Mumbai (a TBS venture) have worked
          with some of the world's leading brands and companies.
        </p>

        <picture>
          <source
            media="(min-width: 650px)"
            srcset={require("../images_fl/past-students.webp")}
          />
          <source
            media="(min-width: 465px)"
            srcset={require("../images_fl/brands_mobile.jpg")}
          />
          <img
            className="desk-img"
            loading="lazy"
            src={require("../images_fl/past-students.webp")}
            alt="IIEDM Alumini Placement Companies"
          />
          <img
            className="mobile-img"
            loading="lazy"
            src={require("../images_fl/brands_mobile.jpg")}
            alt="IIEDM Alumini Placement Companies"
          />
        </picture>
      </div>
    </div>
  );
};

export default Company;
