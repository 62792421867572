import React from 'react'
import backgroundImg from '../images_fl/corporateclients.gif';
export default function Contact() {
  return (
    <div>
        <div className="careerz">

            <div id="enquiry" className="enquiryz-box">
                <div className="bg-img" style={{backgroundImage: `url(${backgroundImg})`}}></div>
                <div className="apply-form">
                    <h2>Get in touch</h2>
                    <form action="corporate_enquiry_form.php" id="" name="" method="post" role="form">
                        <label for="name">Full name</label>
                        <input type="text" id="name" name="name" placeholder="Your Full Name" required/>
                        <label for="email">Email <small>*</small></label>
                        <input type="email" id="email" name="email" value="" placeholder="Your Email" className="required email sm-form-control"/>
                        <label for="mobile">Mobile <small>*</small></label>
                        <input type="number" name="mobile" id="mobile" value="" placeholder="Your Mobile No." size="22" tabindex="5" className="sm-form-control required"/>
                        <label for="organization">Organization <small>*</small></label>
                        <input type="text" id="organization" name="organization" value="" placeholder="Company Name " className="required email sm-form-control"/>
                        <label for="message">Message <small>*</small></label>
                        <textarea name="message" id="message" rows="6" tabindex="11" className="sm-form-control required"></textarea>
                        <button type="submit" id="submit" name="submit">Send</button>
                    </form>
                </div>
            </div>

        </div>
    </div>
  )
}
