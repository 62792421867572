import React from 'react'

export default function Benefits() {
  return (
    <div>
        <div className="careerz franchise">
        
        <div id="video" className="test-headerz">
            <h3>Why IIEDM</h3>
        </div>

        <div  className="test-boxes">
            <div className="text-con-boxz">
                <div className="careerz-box-con">
                    <div className="careerz-box-fit">
                        <h3>Low cost start-up</h3>
                        <p>Considering the absolutely lucrative ROI, with TBS education business franchise in Mumbai, India, the cost of a coaching institute start-up is almost negligible.</p>
                    </div>               
                </div>
            </div>
            <div className="text-con-boxz">
                <div className="careerz-box-con">
                    <div className="careerz-box-fit">
                        <h3>Sound Franchise System</h3>
                        <p>Based on our flawless repute as a digital marketing coaching institute (and agency) in Mumbai, India, we provide you with a solid business foundation.</p>
                    </div>               
                </div>
            </div>
            <div className="text-con-boxz">
                <div className="careerz-box-con">
                    <div className="careerz-box-fit">
                        <h3>Benchmark Franchise Support</h3>
                        <p>Team TBS hand holds you through every step in the process of setting up your digital education business franchise in Mumbai and the rest of India.</p>
                    </div>               
                </div>
            </div>
            <div className="text-con-boxz">
                <div className="careerz-box-con">
                    <div className="careerz-box-fit">
                        <h3>Marketing/Advertising Support</h3>
                        <p>TBS rolls out advertising initiative at a national level and also offers advice for region-specific advertisement.</p>
                    </div>               
                </div>
            </div>
            <div className="text-con-boxz">
                <div className="careerz-box-con">
                    <div className="careerz-box-fit">
                        <h3>Training Support</h3>
                        <p>At IIEDM, we undertake faculty & counselor training. It induces induction programs, conducts ongoing training for new courses.</p>
                    </div>               
                </div>
            </div>
            <div className="text-con-boxz">
                <div className="careerz-box-con">
                    <div className="careerz-box-fit">
                        <h3>Competitive course fees</h3>
                        <p>Thanks to the reasonable course fees at our coaching institute IIEDM, TBS already churns out a large number of students every year.</p>
                    </div>               
                </div>
            </div>
        </div>

        
    </div>
    </div>
  )
}
