import React from "react";

export default function Mid2() {
  return (
    <div>
      <div className="ad-modules content-hero">
        <div className="header">
          <h3>
            Offering one of the Top Social Media Marketing Certification Courses
            and Training in Mumbai
          </h3>
          <p>
            Get hands-on training from the experts at our leading training
            institute, make an ‘Impression’ and grant yourself the most coveted
            career with the best SMM certification course in Mumbai.
          </p>
        </div>
        <div className="module-boxes">
          <div className="apdm-box">
            <div className="title">
              <h3>
                Social Media Marketing Certification Course that helps you
                create and be the 'Trend'.
              </h3>
            </div>
            <div className="content">
              <p>
                At IIEDM- an Award Winning Training Institute in Mumbai, we
                offer a dynamic{" "}
                <strong>Social Media Marketing certification course</strong>{" "}
                backed by years of experience that gives you the power of
                knowledge. The Power to understand the audience, techniques and
                tools. The power to create an avenue of excellence for your
                career. Our{" "}
                <strong>
                  advanced Social Media Marketing certification courses
                </strong>
                , excellent training and the finest industry experts are the
                wizards that help you learn the spells (techniques) for you to
                create your magic in the social world that compels people to
                follow the three coveted words 'Like, Comment and Share'.
              </p>
              <p>
                Backed by agency experience, we offer one of the{" "}
                <strong>
                  best social media marketing certification courses in Mumbai
                </strong>{" "}
                that enables you to not just learn but to be Social. With the
                best practical experience at your aid, this certification course
                gives you the training you need to speak the language of the
                internet community, so fluently that it sounds like a melody of
                which people 'Just can't get enough.’ From tweets that Twitter
                and posts that shimmer on the feed, through these certification
                courses we provide fine training for all platforms of social
                media in Mumbai to ensure that you always stay 'On Fleek'.
                <p>Tags:</p>
                <h4>
                  Social Media Marketing courses in Mumbai, Best Social Media
                  Marketing courses in Mumbai, Top Social Media marketing
                  courses, Social Media Marketing courses with placements, Learn
                  Facebook & Instagram Marketing
                </h4>
              </p>
            </div>
            <div className="tags">
              <a className="blue-button" href="#contact">
                Request a Brochure
              </a>
            </div>
          </div>
          <div id="syllabus" className="modules-box">
            <div className="title">
              <p>Modules Covered</p>
            </div>
            <div className="ad-accordian">
              <div className="tabs">
                <div className="tab">
                  <input type="radio" id="rd1" name="rd" checked />
                  <label className="tab-label" for="rd1">
                    <p>Introduction To Social Media</p>
                  </label>
                  <div className="tab-content">
                    <ul>
                      <li>What is social media</li>{" "}
                      <li>Latest stats and trends about social media</li>
                      <li>Why businesses need it / benefits of social media</li>
                      <li>Overview of most popular social media platforms</li>
                    </ul>
                  </div>
                </div>
                <div className="tab">
                  <input type="radio" id="rd2" name="rd" />
                  <label className="tab-label" for="rd2">
                    <p>Using Twitter</p>
                  </label>
                  <div className="tab-content">
                    <ul>
                      <li>Introduction to Twitter</li>
                      <li>
                        Hashtags, trending topics, live tweeting, influencers
                      </li>
                      <li>
                        Twitter for PR, brand building, customer engagement
                      </li>
                      <li>Creating Twitter marketing strategy</li>
                      <li>Case studies & best practices</li>
                    </ul>
                  </div>
                </div>
                <div className="tab">
                  <input type="radio" id="rd4" name="rd" />
                  <label className="tab-label" for="rd4">
                    <p>Influencer Marketing</p>
                  </label>
                  <div className="tab-content">
                    <ul>
                      <li>What is influencer marketing?</li>
                      <li>How to create an influencer marketing strategy</li>
                      <li>Influencer outreach: How to contact influencers</li>
                      <li>Do's and Don'ts</li>
                    </ul>
                  </div>
                </div>
                <div className="tab">
                  <input type="radio" id="rd5" name="rd" />
                  <label className="tab-label" for="rd5">
                    <p>Facebook & Instagram Marketing</p>
                  </label>
                  <div className="tab-content">
                    <ul>
                      <li>Orientation to Facebook brand pages</li>
                      <li>Building a Facebook strategy</li>
                      <li>Facebook insights</li>
                      <li>Relevant case studies</li>
                      <li>Instagram Strategy</li>
                      <li>Hashtags</li>
                      <li>Content Creation</li>
                    </ul>
                  </div>
                </div>
                <div className="tab">
                  <input type="radio" id="rd6" name="rd" />
                  <label className="tab-label" for="rd6">
                    <p>Other Social Media Channels</p>
                  </label>
                  <div className="tab-content">
                    <ul>
                      <li>Youtube</li>
                      <li>LinkedIn</li>
                      <li>Other Social Media platforms</li>
                    </ul>
                  </div>
                </div>
                <div className="tab">
                  <input type="radio" id="rd7" name="rd" />
                  <label className="tab-label" for="rd7">
                    <p>Social Media Ads & Paid Marketing</p>
                  </label>
                  <div className="tab-content">
                    <ul>
                      <li>Types of social media ads</li>
                      <li>Facebook Ads</li>
                      <li>Instagram Ads</li>
                      <li>Social media advertising tips</li>
                    </ul>
                  </div>
                </div>
                <div className="tab">
                  <input type="radio" id="rd8" name="rd" />
                  <label className="tab-label" for="rd8">
                    <p>Social Media Marketing Strategy</p>
                  </label>
                </div>

                <div className="tab">
                  <input type="radio" id="rd9" name="rd" />
                  <label className="tab-label" for="rd9">
                    <p>Interview Preparation</p>
                  </label>
                </div>

                <div className="tab">
                  <input type="radio" id="rd10" name="rd" />
                  <label for="rd10" className="tab-close">
                    Close others &times;
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
