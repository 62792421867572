import React from 'react'

export default function Workshops(props) {
  return (
        <div className="best-course">
                    <img loading="lazy" src={props.imgPath} alt = {props.imgText}/>
                    <div className="course-title">
                        <span className="title" style={{fontSize:"20px"}}>{props.title}</span>
                        <p style={{fontSize:"18px"}}>{props.para}</p>
                    </div>
              </div>
  )
}
