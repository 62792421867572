import React from 'react'

export default function Mid() {
  return (
    <div>
        <div className="careerz">
        
         
        <div className="awardszz">
            <div className="award-header">
                <h2>TBS steals the show at the 5th Indian Education Awards 2015</h2>
            </div>
            <div className="award-content">
                <p>TBS - Digital Marketing Institute, has been conferred with an esteemed award at "The 5th Indian Education Awards 2015' held in Delhi. TBS won the coveted "Best Digital Marketing Institution".<br/>
                    Considered to be the Asia's biggest education show, The Indian Education Award is one important accolade that identifies superiority in the sector of education. The awards were held at a magnificent ceremony organized in New Delhi. The council aims to recognize achievers and innovators, who have immensely contributed towards the excellence and growth of the education sector. The awards were presented by Mr. Pawan Agarwal, Joint Secretary, Ministry of Skill Development & Entrepreneurship, Government of India and Ms. Ritu Marya, Editor-In-Chief, Franchise India and other leading public figures.</p>
                <p>The Indian Education Awards organized by Franchise India consider standards, ethics and best practice at the top of their program. Through positive recognition of those that lead the way, these awards are seen as industry's top tribute.</p>
            </div>

            <section className="testimonial">
                <div className="inner">
                    <div className="cederholm" id="quote-wrapper">
                    <div className="quote">
                      <blockquote id="quote-quote">Winning this award is like unleashing the ultimate pinnacle of success for our institute. We are immensely proud of all the candidates we have trained and the relationships we share with them, making this kind of high-profile industry recognition all the more rewarding. We aim at creating the best possible environment for our alumni so that we can in turn provide them with the highest level of education.</blockquote>
                      <cite id="quote-attrib"><img width="60" height="60" src={require("../images_fl/hitesh-khandor.jpg")}  alt="Hitesh Khandor at 5th indian education awards 2015" title="Hitesh Khandor at 5th indian education awards 2015"/>Mr. Hitesh Khandor, the Co-Founder of TBS</cite>
                    </div>
                  </div>
                </div>
              </section>
        </div>
        

    </div>
    </div>
  )
}
