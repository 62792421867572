import '../App.css';
import Navbar from '../components/Navbar/Navbar';
import Header from '../components/Header/Header';
import Company from '../components/Companies/Companies';
import Mid from '../components/Mid/Mid';
import Course from '../components/Courses/Courses';
import Student from '../components/Students/Students';
import Testimonials from '../components/Testimonials/Testimonials';
import Advertisement from '../components/Advertisement/Advertisement';
import Media from '../components/Media/Media';
import Question from '../components/Questions/Questions';
import Footer from '../components/Footer/Footer';

function Home() {
  return (
    <div>
      <Navbar/>
      <Header/>
      <Company/>
      <Mid phone="7738244304"/>
      <Course/>
      <Student/>
      <Testimonials/>
      <Advertisement/>
      <Media/>
      <Question/>
      <Footer/>
    </div>
  );
}

export default Home;
