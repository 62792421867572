import '../App.css'
import Navbar from '../components/Navbar/Navbar';
import Header from '../components/Header_charni/Header';
import Company from '../components/Companies_charni/Companies';
import Mid from '../components/Mid/Mid';
import Course from '../components/Courses_charni/Courses';
import Testimonials from '../components/Testimonials/Testimonials';
import Media from '../components/Media/Media';
import Map from '../components/Map/Map';
import Question from '../components/Questions_charni/Questions';
import Footer from '../components/Footer/Footer';
import Call from '../components/Call/Call';
import Award from '../components/Award/Award';

function Malad() {
  return (
    <div>
      <Navbar/>
      <Header location = "Malad" phone="8879888513"/>
      <Company/>
      <Course location = "Malad"/>
      <Call text1="West Mumbai! Digital Marketing is the new calling!" text2="An exciting opportunity awaits digital marketing fanatics in Malad, Andheri, Kandivali, Goregaon, Borivali and Lokhandwala." phone="8879888513"/>
      <Mid phone="8879888513"/>
      <Award/>
      <Testimonials/>
      <Media/>
      <Map link="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15073.68649725805!2d72.8367183!3d19.1767785!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x318c90cd2f797a49!2sIIEDM%20-%20Digital%20Marketing%20Courses%20in%20Malad-Andheri-Goregaon%20Mumbai!5e0!3m2!1sen!2sin!4v1623405982838!5m2!1sen!2sin"/>
      <Question location="Malad"/>
      <Footer/>
    </div>
  );
}

export default Malad;
