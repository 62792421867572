import react from 'react'

const Question=(props)=>{
    return(
        <div>
            <div className="FAQ-section">
    <div className="col">
      <p className="faq-header">Frequently Asked Questions</p>
      <div className="tabs" style={{fontSize:"18px"}}>
        <div className="tab">
          <input type="radio" id="rd1" name="rd"/>
          <label className="tab-label" for="rd1">What is Digital Marketing?</label>
          <div className="tab-content">
            <p>Before the internet advancements, people only used the traditional methods of advertising such as billboards, TV, etc. But today as more and more people are using the internet, brands have also started advertising over the internet through various channels such as social media, search engine marketing, etc.</p><br/>
            <p>IIEDM- a leading digital marketing training institute in {props.location},  Mumbai works towards offering the best training and knowledge through the comprehensive training, courses and classes that help you excel.</p>
          </div>
        </div>
        <div className="tab">
          <input type="radio" id="rd2" name="rd"/>
          <label className="tab-label" for="rd2">Who can pursue digital marketing?</label>
          <div className="tab-content">
            <p>It can be pursued by anyone and everyone who has a keen interest to work in a dynamic environment and is an internet enthusiast. Anyone who’s looking for a career change and even fresh graduates can excel in this field. Getting trained from a reputed Institute like IIEDM, Mumbai might help you to begin your career with the right start. You can contact our {props.location} Centre and various other centres across Mumbai to inquire about our top-notch courses.</p>
          </div>
        </div>
        <div className="tab">
          <input type="radio" id="rd3" name="rd"/>
          <label className="tab-label" for="rd3">What are the benefits of doing a digital marketing course from the IIEDM {props.location} Branch?</label>
          <div className="tab-content">
            <p>IIEDM has industry experts hired as teaching facilities along with courses, classes and training programs that teach you everything right from the basics to the advanced level. IIEDM is a digital marketing institute in {props.location}, and all of Mumbai that is backed by agency experience. We at IIEDM, {props.location}, Mumbai during our classes teach our students through live projects giving them hands-on practical experience during their training. 95% of candidates that opted for IIEDM’s courses, classes and training are currently employed and work with some of the biggest brands in Mumbai and around the world.</p>
          </div>
        </div>
        <div className="tab">
          <input type="radio" id="rd4" name="rd"/>
          <label className="tab-label" for="rd4">How can I commute to the institute?</label>
          <div className="tab-content">
            <p>IIEDM {props.location} is situated in the heart of the city, Mumbai, therefore it is easily accessible for people living in South Mumbai. The location of IIEDM training institute is well connected by railways and roadways. This makes it easily possible for the aspirants of our courses to reach our training institute and get closer to a bright future.</p><br/>
            <p>IIEDM institute offers the finest digital marketing training, courses and classes in {props.location}, Mumbai that allows you to avail the necessary knowledge and training that is worth every effort and money you invest.</p>
          </div>
        </div>
        <div className="tab">
          <input type="radio" id="rd5" name="rd"/>
          <label className="tab-label" for="rd5">Does IIEDM offer job placements?</label>
          <div className="tab-content">
            <p>IIEDM offers advanced Digital Marketing classes, training and courses in {props.location}, Mumbai with placement offers in some of the best companies. We also prepare our students studying at all branches for interviews by helping them with their resumes and conducting mock interviews.</p><br/>
            <p>We at IIEDM training institute run successful digital marketing training courses and classes in {props.location}, Mumbai that not just helps you attain essential knowledge and training but may also help you avail good opportunities through the placements our institute offers to the students of our digital marketing courses.</p>
          </div>
        </div>
        
  
      </div>
    </div>
  
  </div>
        </div>
    );
};

export default Question;