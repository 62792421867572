import '../App.css'
import Navbar from '../components/Navbar/Navbar';
import Company from '../components/Companies_charni/Companies';
import Footer from '../components/Footer/Footer';
import Head from '../components/Testimonials/Head';
import Mid from '../components/Testimonials/Mid';
import Advertisement from '../components/Advertisement/Advertisement';
import Review from '../components/Testimonials/Review';



function Testimonial() {
  return (
    <div>
      <Navbar/>
      <Head/>
      <Company/>
      <Mid/>
      <Advertisement/>
      <Review/>
      <Footer/>
    </div>
  );
}

export default Testimonial;
