import React from "react";

export default function Questions() {
  return (
    <div>
      <div className="FAQ-section">
        <div className="col">
          <p className="faq-header">
            (FAQ’s) Your Questions, Our Solutions- Explained and Simplified
          </p>
          <div className="tabs">
            <div className="tab">
              <input type="radio" id="rd81" name="rd" />
              <label className="tab-label" for="rd81">
                What is Social Media Marketing?
              </label>
              <div className="tab-content">
                <p>
                  Social Media Marketing basically belongs to the field of
                  internet marketing wherein networking sites are used as tools
                  for promoting products or services.
                </p>
                <br />
                <p>
                  At IIEDM, an award winning institute in Mumbai, we offer
                  training in the finest SMM certification courses that help you
                  know the nitty-gritty of the subject and excel it through the
                  right knowledge and practical experience.
                </p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd91" name="rd" />
              <label className="tab-label" for="rd91">
                Who can opt for social media marketing courses?
              </label>
              <div className="tab-content">
                <p>
                  A social media marketing certification course is suitable for
                  SMM enthusiasts, executives, specialists or just anyone that
                  holds the curiosity to explore the social world. Oh and your
                  profession doesn’t matter, to enroll for the certification
                  course what matters is your passion!
                </p>
                <br />
                <p>
                  At IIEDM- an experienced digital training institute in Mumbai,
                  we cater to everyone who wishes to gain knowledge of the
                  field. We help people avail the best opportunities by
                  providing them with the right training, knowledge, and
                  practice through our exceptional SMM certification course.
                </p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd101" name="rd" />
              <label className="tab-label" for="rd101">
                What all platforms does the course cover?
              </label>
              <div className="tab-content">
                <p>
                  {" "}
                  With our dynamic SMM certification course taught at our
                  training institute in Mumbai, we cover all the possible
                  available social media platforms like
                </p>
                <br />
                <p>
                  At IIEDM- an experienced digital training institute in Mumbai,
                  we cater to everyone who wishes to gain knowledge of the
                  field. We help people avail the best opportunities by
                  providing them with the right training, knowledge, and
                  practice through our exceptional SMM certification course.
                </p>
                <ul>
                  <li>Facebook</li>
                  <li>Instagram</li>
                  <li>LinkedIn</li>
                  <li>Twitter</li>
                  <li>YouTube</li>
                </ul>
                <br />
                <p>
                  At IIEDM- a leading digital training institute in Mumbai, we
                  aim to acquaint every individual with the necessary knowledge
                  through one of the best SMM certification courses in Mumbai
                  that we offer which helps them to move ahead through practical
                  and theoretical knowledge.
                </p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd11" name="rd" />
              <label className="tab-label" for="rd11">
                Career Opportunities after completing an SMM course?
              </label>
              <div className="tab-content">
                <p>
                  The opportunities available after the completion of an SMM
                  certification course solely depend on the talent and
                  experience you possess. The SMM training that we offer through
                  our certification course in Mumbai also involves placements
                  that help students to get into the best companies. The
                  opportunities after completing the certification course are as
                  follows:
                </p>
                <ul>
                  <li>SMM Executive</li>
                  <li>SMM Analyst</li>
                  <li>SMM Manager</li>
                  <li>SMM Associate</li>
                  <li>SMM Copywriter</li>
                  <li>Paid Marketing Specialist and more</li>
                </ul>
                <br />
                <p>
                  IIEDM is a training institute that works towards offering the
                  finest social media marketing training in Mumbai and several
                  other places through certification courses that helps an
                  individual to be prepared thoroughly and offers multiple
                  opportunities that they can choose from.
                </p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd12" name="rd" />
              <label className="tab-label" for="rd12">
                Do I need to have a marketing background?
              </label>
              <div className="tab-content">
                <p>
                  No, you aren’t required to have a marketing background but
                  instead, you should have the interest and that would be enough
                  as we provide training in all the basics and the advances
                  through our comprehensive social media marketing certification
                  course in Mumbai. At IIEDM, a credible SMM training institute
                  in Mumbai, our certification course turns a novice into a
                  master by giving him/her every knowledge required and the
                  necessary training to ace the space.
                </p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd13" name="rd" />
              <label className="tab-label" for="rd13">
                Why is a Social Media Marketing course important?
              </label>
              <div className="tab-content">
                <p>
                  Today, since everyone has hopped onto the social bandwagon,
                  it’s imperative to understand and learn how marketing works on
                  such platforms. The audience on networking sites continues to
                  rise making it the perfect marketing solution for multiple
                  businesses. A social media marketing certification course in
                  Mumbai or any other part of the world holds great significance
                  as such a course gives you an in-depth understanding and
                  training to prepare yourself for the most coveted industry,
                  and allows you to learn the tools and techniques to excel in
                  it.
                </p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd14" name="rd" />
              <label className="tab-label" for="rd14">
                Is the SMM course offered purely theoretical?
              </label>
              <div className="tab-content">
                <p>
                  IIEDM is an experienced training institute in Mumbai that
                  believes in the power of theory and practicality. The SMM
                  certification course offered by us in Mumbai is an
                  amalgamation of both- theory and practical training, that is
                  made possible via a comprehensive syllabus and live projects
                  that allow students to enjoy the best of both worlds - in one
                  certification course.
                </p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd15" name="rd" />
              <label className="tab-label" for="rd15">
                What will I learn?
              </label>
              <div className="tab-content">
                <p>
                  With our SMM certification course in Mumbai, you will get a
                  thorough training that will help you gain the knowledge of the
                  entire process, how you can create and interact, conduct
                  research, manage time, create a workflow, how to use social
                  media platforms, how to create trending content for each
                  platform and a lot more. We at IIEDM, one of the best training
                  institutes in Mumbai offer you comprehensive knowledge of the
                  industry and SMM through our excellent certification course
                  for you to excel.
                </p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd16" name="rd" />
              <label className="tab-label" for="rd16">
                Does IIEDM offer job placements?
              </label>
              <div className="tab-content">
                <p>
                  Yes, with IIEDM you don't just partner with any other training
                  institute in Mumbai, you partner with excellence. Through us,
                  you get access to the best Social media marketing
                  certification courses but also the industry experts and 100%
                  placements.
                </p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd17" name="rd" />
              <label className="tab-label" for="rd17">
                Why should I invest?
              </label>
              <div className="tab-content">
                <p>
                  Well, one reason would be that we’re the best social media
                  marketing training institute in Mumbai and the other would be
                  that we don’t just teach how to use social media through our
                  Facebook certification course and others. Our certification
                  courses impart knowledge of the unknown, the factors that
                  drive sales, build connections and create engagements - giving
                  you a wholesome training.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
