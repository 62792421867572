import React from 'react'

export default function Review() {
  return (
    <div>
        <div class="careerz">
        <div class="goorev ppcad" id="written">

            <div class="test-headerz">
                Our Student's Speaks
            </div>

            <div class="box2">
                <div class="testz">
    
                    <div class="itez">
                        <div class="item">
                            <div class="rev">
                                <p>"With Faculties as experienced, willing to come down to your level & imparting their knowledge,sharing real time facts & figures, definitely puts you on course for next big thing"
                                </p>
                            </div>
                            <div class="person">
                                <span>- PRASHANT BHURKE</span>
                                <span class="title">Manager - Sony Computer Entertainment Europe</span>
                            </div>
                        </div>
                    </div>
                    <div class="itez">
                        <div class="item">
                            <div class="rev">
                                <p>"Working as Content Writer from last four years, I was looking for a Social Media Course to expand my career horizon. A friend of mine suggested to join Social Media Course at TBS and it really worked for me."
                                </p>
                            </div>
                            <div class="person">
                                <span>- NIKITA PADAVE</span>
                                <span class="title">Content Writer at Housing.com</span>
                            </div>
                        </div>
                    </div>
                    <div class="itez">
                        <div class="item">
                            <div class="rev">
                                <p>"The new age digital aspect of Marketing has made it more accountable & respectable.Hence I wanted to gain in-depth knowledge of this domain. TBS has helped me understand the nuances of Digital Marketing."
                                </p>
                            </div>
                            <div class="person">
                                <span>- RAHUL SAWANT</span>
                                <span class="title">Assistant Manager - netCORE Solutions Pvt. Ltd.</span>
                            </div>
                        </div>
                    </div>
                    <div class="itez">
                        <div class="item">
                            <div class="rev">
                                <p>"With TBS, it has been a fun learning experience. The faculty is really nice and helps clear all your concepts and give you practical insights on how Digital Marketing works in the Corporate World."
                                </p>
                            </div>
                            <div class="person">
                                <span>- MEHUL DAK</span>
                                <span class="title">Asst. Manager - Marketing at Mahindra Lifespaces</span>
                            </div>
                        </div>
                    </div>
                    <div class="itez">
                        <div class="item">
                            <div class="rev">
                                <p>"I found the course to be very interesting,I learned a lot of things directly linked with SEO that I didn't know before. I really enjoyed the practical aspect of the training at TBS."
                                </p>
                            </div>
                            <div class="person">
                                <span>- PRERNA TALREJA</span>
                                <span class="title">Media Planner at Ogilvy & Mather</span>
                            </div>
                        </div>
                    </div>
                    <div class="itez">
                        <div class="item">
                            <div class="rev">
                                <p>"I did my course in Digital Marketing from TBS. I had a real good experience understanding the concept and nuances of the subject.Don't think twice before coming to this place."
                                </p>
                            </div>
                            <div class="person">
                                <span>-  ROHAN SURVE</span>
                                <span class="title">Marketing Manager at BlueStone.com</span>
                            </div>
                        </div>
                    </div>
                    <div class="itez">
                        <div class="item">
                            <div class="rev">
                                <p>"Working on live projects and the practical examples by the faculty made it easy for me to explore the digital marketing industry. All the best TBS.Keep grooming the young digital marketers."
                                </p>
                            </div>
                            <div class="person">
                                <span>- VIVEK SHARMA</span>
                                <span class="title">Digital Marketing Executive - BLUWIT</span>
                            </div>
                        </div>
                    </div>
                    <div class="itez">
                        <div class="item">
                            <div class="rev">
                                <p>"As Dalai Lama has rightly said: 'Share your knowledge. It's a way to achieve immortality.' More than being knowledgeable, it's important to share & spread knowledge. TBS is engaged into a benevolent task of sharing knowledge & preparing everyone to be digital savvy."
                                </p>
                            </div>
                            <div class="person">
                                <span>- NEHA SHAH</span>
                                <span class="title">SEO Analyst at CMSS</span>
                            </div>
                        </div>
                    </div>
                    <div class="itez">
                        <div class="item">
                            <div class="rev">
                                <p>"I am very happy to finish my course from TBS. It was a very nice experience. I got to learn many new things, whatever I learned from here I implement that in my workplace. The trainers were really good."
                                </p>
                            </div>
                            <div class="person">
                                <span>- ZEUS BALAPORIA</span>
                                <span class="title">Executive - Online Marketing at Inkfruit.com</span>
                            </div>
                        </div>
                    </div>
     
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}
