import React from 'react'

export default function Review() {
  return (
    <div>
        <div className="goorev ppcad" id="google-reviews">
        <div className="box1">
            <div className="details">
                <img loading="lazy" src={require("../images_fl/googlereviewsblue.png")} alt="IIEDM Google Reviews"/>
                <p className="google-rew">15000+ Candidates trained and the journey continues..</p>
                <p>Today, we are proud to have successfully trained many marketers and college students in various domains of digital marketing. Our Alumni consists of specialists working in significant digital marketing positions at leading companies.</p>
                <p>Take a look at what our past students who have completed the certification course and training in Mumbai, who are now working with some of the world's leading brands, have to say about the course.</p>
            </div>
            <div className="goobtn">
                <a href="https://bit.ly/3jYoG9R" target="_blank" className="blue-button">Explore All Reviews &gt;</a>
            </div>
        </div>
        <div className="box2">
            <div className="testz">

                <div className="itez">
                    <div className="item">
                        <div className="rev">
                            <p>I joined two months ago and by far they have lived up to my expectations. The best part is that you get to use the paid tools and practically execute the concept throughout the certification course and training period.</p>
                        </div>
                        <div className="person">
                            <span>- Sampada Kabad</span>
                            <img className="stars" loading="lazy" src={require("../images_fl/5-stars.png")} alt="IIEDM rated 5 stars"/>
                        </div>
                    </div>
                </div>

                <div className="itez">
                    <div className="item">
                        <div className="rev">
                            <p>Great faculty. Discuss case studies while teaching. Recommends CVs to digital marketing
                                agencies/companies and many more. Highly recommended for freshers.</p>
                        </div>
                        <div className="person">
                            <span>- Shrutika Balsaraf</span>
                            <img className="stars" loading="lazy" src={require("../images_fl/5-stars.png")} alt="IIEDM rated 5 stars"/>
                        </div>
                    </div>
                </div>


                <div className="itez">
                    <div className="item">
                        <div className="rev">
                            <p>The faculty members are great and the fact that they are working professionals is a big
                                boon
                                for the students.It's a great institute that offers the best certification course and training in Mumbai.</p>
                        </div>
                        <div className="person">
                            <span>- Sneha Roy</span>
                            <img className="stars" loading="lazy" src={require("../images_fl/5-stars.png")} alt="IIEDM rated 5 stars"/>
                        </div>
                    </div>
                </div>


                <div className="itez">
                    <div className="item">
                        <div className="rev">
                            <p>I just completed my certification course in Analytics. Always ready with new updates, the
                                teaching skills of the staff were worth my bucks.</p>
                        </div>
                        <div className="person">
                            <span>- Ajay Bakshi</span>
                            <img className="stars" loading="lazy" src={require("../images_fl/5-stars.png")} alt="IIEDM rated 5 stars"/>
                        </div>
                    </div>
                </div>


                <div className="itez">
                    <div className="item">
                        <div className="rev">
                            <p>The Digital Marketing certification course (Advanced Program in Digital Marketing) was very practical,
                                interactive and very informative.Highly Recommendable!!!</p>
                        </div>
                        <div className="person">
                            <span>- Leena Gholap</span>
                            <img className="stars" loading="lazy" src={require("../images_fl/5-stars.png")} alt="IIEDM rated 5 stars"/>
                        </div>
                    </div>
                </div>


                <div className="itez">
                    <div className="item">
                        <div className="rev">
                            <p>Its a complete practical course where one can actually learn hands on. I would recommend
                                this
                                institute for any aspirant looking to venture into the digital marketing field because of the excellent certification course and training they offer in Mumbai.</p>
                        </div>
                        <div className="person">
                            <span>- Ashford Carlos</span>
                            <img className="stars" loading="lazy" src={require("../images_fl/5-stars.png")} alt="IIEDM rated 5 stars"/>
                        </div>
                    </div>
                </div>



            </div>
        </div>
    </div>
    </div>
  )
}
