import '../App.css';
import Navbar from '../components/Navbar/Navbar';
import Head from '../components/CoorporateTraining/Head';
import Clients from '../components/CoorporateTraining/Clients';
import Mid from '../components/CoorporateTraining/Mid';
import Mid2 from '../components/CoorporateTraining/Mid2';
import MainWorkshop from '../components/CoorporateTraining/MainWorkshop';
import Contact from '../components/CoorporateTraining/Contact';
import CaseStudy from '../components/CoorporateTraining/CaseStudy';
import Call from '../components/CoorporateTraining/Call';
import Testimonial from '../components/CoorporateTraining/Testimonial';
import Questions from '../components/CoorporateTraining/Questions';
import Footer from '../components/Footer/Footer';

function CoorporateTraining() {
  return (
    <div>
      <Navbar/>
      <Head/>
      <Clients/>
      <Mid/>
      <Mid2/>
      <MainWorkshop/>
      <Contact/>
      {/* <CaseStudy/> */}
      <Call/>
      <Testimonial/>
      <Questions/>
      <Footer/>
    </div>
  );
}

export default CoorporateTraining;
