import React from 'react'

export default function 
() {
  return (
    <div>
        <div id="awards" className="row awards-fold new">
            <div className="awards-banner">
            <img loading="lazy" width="100%" src={require("../images_fl/awards.webp")} alt="IIEDM Awards and Recognition"/>
            </div>
        </div>
    </div>
  )
}
