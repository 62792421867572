import react from "react";

const Course = (props) => {
  return (
    <div>
      <div id="courses" className="row third-fold pb-70" style={{ background: "#fafafa" }}>
        <p style={{fontSize:"30px"}}>Top Digital Marketing Courses With Placements in {props.location}</p>
        <p className="brand-style-h">
          Learn Digital Marketing from IIEDM - an Award-Winning Institute of
          Digital Marketing
        </p>
        <div className="courses center">
          <div className="center-content">
            <h2>Looking for Digital Marketing Courses in {props.location}?</h2>
            <p>
              End your Pursuit with IIEDM- The Top Digital Marketing Institute
              in {props.location}, Mumbai.
            </p>
            <p className="accent">
              Hands On Training - Expert Faculty - 100% Job Assistance
            </p>
            <p>
              IIEDM is a top-notch Institute and training centre headquartered
              in Mumbai. Experience, expertise, tools and techniques have been
              IIEDM’s pillars of distinction that have helped us to offer
              diverse and unmatched learning experiences with our various{" "}
              <strong>courses and classes at {props.location}</strong>, Mumbai
              training aspirants to sit on the throne of excellence.
            </p>
            <p>
              Along with a well-rounded curriculum of courses, practical
              learning is one of the most prominent facilities that gives the
              digital enthusiasts a real time experience. Backed by agency
              experience, our{" "}
              <strong>institute offers various courses at {props.location}</strong>{" "}
              and other areas in Mumbai that transform newbies into digital
              ninjas by teaching them the latest industry insights with hands-on
              practical experience and impeccable training.
            </p>

            <p>
              Located in one of the most approachable areas of the city our{" "}
              <strong>IIEDM training institute at {props.location}</strong> is easy
              to reach for people living in South Mumbai and the conjoining
              regions. Being the top digital marketing institute in Mumbai,
              offering top-notch training, courses and classes we aim at
              transforming young aspirants into industry experts that strategise
              to create an impact through the best courses and exceptional
              training.
            </p>

            <p>Tags:</p>
            <p style={{fontWeight:"bold"}}>
              Best Digital Marketing courses in {props.location}, Top Digital
              Marketing Institutes in South Mumbai, Digital Marketing courses in {props.location} with Placements
            </p>

            <div id="chooseUs" className="counter-wrapper">
              <div className="features frow">
                <div className="fr-box">
                  <p className="numb">15000+</p>
                  <p className="numbp">PROFESSIONALS TRAINED</p>
                </div>
                <div className="fr-box">
                  <p className="numb">650+</p>
                  <p className="numbp">BATCHES CONDUCTED</p>
                </div>
                <div className="fr-box">
                  <p className="numb">90+</p>
                  <p className="numbp">Expert Faculty</p>
                </div>
                <div className="fr-box">
                  <p className="numb">10+</p>
                  <p className="numbp">Years of Experience</p>
                </div>
              </div>
            </div>

            <div className="center-carousel"></div>
          </div>
          <div className="courses-boxes">
            <div className="courses-box">
              <div className="course-img">
                <img
                  src="images_fl/dmcp.jpg"
                  alt="Digital Marketing Certificate Program"
                />
              </div>
              <div className="course-detail">
                <h3>Digital Marketing Certificate Program (DMCP)- 3 months</h3>
                <p>
                  A Digital Marketing Certificate Program Conducted by industry
                  experts takes candidates through the nook and corner of
                  digital marketing.
                </p>
                <div className="reqBr">
                  <a className="blue-button" href="#contact">
                    Request a Brochure
                  </a>
                </div>
              </div>
            </div>
            <div className="courses-box">
              {/* <div className="course-img">
            <img loading="lazy" src="images_fl/apdm.jpg" alt="Advanced Program in Digital Marketing"/>
          </div> */}
              <div className="course-detail">
                <h3>Advanced Program In Digital Marketing (APDM)-4 months</h3>
                <p>
                  With Advanced Program in Digital Marketing, the focus is on
                  the changing trends, best practices and advance tools in
                  digital marketing
                </p>
                <div className="reqBr">
                  <a className="blue-button" href="#contact">
                    Request a Brochure
                  </a>
                </div>
              </div>
            </div>
            <div className="courses-box">
              {/* <div className="course-img">
            <img loading="lazy" src="images_fl/fsdm.jpg" alt="Full Stack Digital Marketing Program"/>
          </div> */}
              <div className="course-detail">
                <h3>Full Stack Digital Marketing Program (FSDM)- 5 months </h3>
                <p>
                  A master program covering all the major aspects of digital
                  marketing, helping candidates in mastering the art of digital
                  marketing completely.
                </p>
                <div className="reqBr">
                  <a className="blue-button" href="#contact">
                    Request a Brochure
                  </a>
                </div>
              </div>
            </div>
            <div className="courses-box">
              {/* <div className="course-img">
            <img loading="lazy" src="images_fl/uiux.jpg" alt="Certification Course in UI/UX"/>
          </div> */}
              <div className="course-detail">
                <h3>Certification Course in UI/UX</h3>
                <p>
                  Learn the current UI/UX trends across various platforms such
                  as web and mobile applications. Get skilled in using the
                  latest tools used in the industry.
                </p>
                <div className="reqBr">
                  <a className="blue-button" href="#contact">
                    Request a Brochure
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Course;
