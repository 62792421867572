import React from 'react'

export default function Programs() {
  return (
    <div>
        <div class="enq-pro">
        <div class="programs">
            <div class="title">
                <p class="main-title">Other Programs</p>
            </div>
            <div class="pro-box">
                <a href="https://iiedm.com/best-digital-marketing-courses-iiedm-tbs.php">Advanced Digital Marketing Certification Course(ADMCC)</a>
                <a href="https://iiedm.com/best-digital-marketing-courses-iiedm-tbs.php">Certification Course in Digital Marketing(CCDM)</a>
                <a href="https://iiedm.com/certification-course-in-seo-mumbai.php">Certification Course in SEO</a>
                <a href="https://iiedm.com/certification-course-in-social-media-marketing-mumbai.php">Certification Course in Social Media Marketing</a>
                <a href="https://iiedm.com/certification-course-in-ppc-adwords-sem-mumbai.php">Certification Course in PPC-Adwords-SEM</a>
                <a href="https://iiedm.com/certification-course-content-writing-mumbai.php">Certification Course in Content Writing</a>

            </div>
        </div>

        <div id="contact" class="secfold-box form-con">
			<div class="form-bg">
				<p class="main-title">Enquire Now</p>
				<div>
					<form id="lead-form" method="post" name="lead-form" action="uiuxenquiry_form.php">
						<input type="text" name="name" placeholder="Name *" required=""/>
						<input type="email" name="email" placeholder="Email *" required=""/>
						<input type="number" name="mobile" pattern="[0-9]" placeholder="Mobile *" required=""/>
						<select name="center" id="center" class="placeholder field" required="">
							<option value="">Select Center</option>
							<option value="Dadar">Dadar</option>
							<option value="Malad">Malad</option>
                            <option value="Dombivli">Dombivli</option>
							<option value="Charni Road">Charni Road</option>
						</select>
						<input type="text" name="message" placeholder="Message *" required=""/>
						<input type="submit" id="myButton" value="ENQUIRE NOW"/>
					</form>
				</div>
				<div class="socialbtns">
					<div class="eqC1"><a href="tel:7738244304"> 
                    <img loading="lazy" src={require("../images_fl/phone-icon.webp")} width="35" height="35" alt="Call us Now!"/>7738244304</a></div>
					<div class="eqC2"><a
						href="https://api.whatsapp.com/send?phone=+917738244304&text=Hi, I would like to know more about Digital Marketing Courses offered by IIEDM."
						target="_blank">
						<img loading="lazy" width="28" height="28" src={require("../images_fl/whatsapp-icon.webp")} alt="Whatsapp us Now!"/>WhatsApp</a></div>
				  </div>
  
			  </div>
  
		</div>
		<div class="contact-boxes">
            <div class="con-boxz"><h3>IIEDM<span class="subtitle">We sow Knowledge, Nurture Potential &amp; Brew Future.</span></h3></div>
            <div class="con-boxz"><h3>Our Headquarter<span class="subtitle">Ground Floor, Hind Services Industrial Premises Co-operative Society Ltd, SVS Rd, Chandrakant Dhuru Wadi, Dadar, Mumbai, Maharashtra-400028<br/><a href="tel:+91-7738244304">+91-7738244304</a></span></h3></div>
            <div class="con-boxz"><h3>Malad Center<span class="subtitle">230, Linkway Estate Next to Chincholi Fire Station, New Link Rd, Malad West, Mumbai, Maharashtra-400064<br/><a href="tel:+91-8879888513">+91-8879888513</a></span></h3></div>
            <div class="con-boxz"><h3>Charni Road Center<span class="subtitle">1st Floor, 55, Ranchhod Bhuvan Bldg, Jagannath Shankar Seth Rd, near shiv krishna restaurant, Charni Road, Mumbai, Maharashtra-400004<br/><a href="tel:+91-8928272418">+91-8928272418</a></span></h3></div>
            
        </div>
    </div>
    </div>
  )
}
