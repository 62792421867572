import React from 'react'
import Workshops from './Workshops'
import img1 from '../images_fl/dm.jpg'
import img2 from '../images_fl/em.jpg';
import img3 from '../images_fl/inboundsn.jpg';
import img4 from '../images_fl/mm.jpg';
import img5 from '../images_fl/orm.jpg';
import img6 from '../images_fl/sem.jpg';
import img7 from '../images_fl/seo.jpg';
import img8 from '../images_fl/smm.jpg';
import img9 from '../images_fl/wa.jpg';
export default function MainWorkshop() {
  return (
    <div>
        <div className="best-digital-hero" style={{background: "#f7f7f7"}}>
        <div className="careerz workshop">
            <div className="header">
                <p className="header-tile">Our Workshops On Digital Marketing</p>
            </div>
            <div className="courses-grid">
                <Workshops imgPath={img1} imgText="Digital Marketing Course" title="Digital Marketing" para="Do you wish to upgrade the digital marketing skills of your team? Write to us and we will get your workshop customized with the apt programs." />
                <Workshops imgPath={img2} imgText="Email Marketing Course" title="Email Marketing" para="Are you looking for professionals to train your team in email marketing? Fill this workshop form and we’ll assist you." />
                <Workshops imgPath={img3} imgText="Inbound Marketing Course" title="Inbound Marketing" para="Want to train your team to leverage the potential of inbound marketing? Get in touch with us and meet your requirements through our programs." />

                <Workshops imgPath={img4} imgText="Mobile Marketing Course" title="Mobile Marketing" para="Do you aspire to provide your team with know-how in mobile marketing? Drop us a request and we’ll arrange a workshop programs for you." />
                <Workshops imgPath={img5} imgText="Online Reputation Management" title="Online Reputation Management" para="Is your team struggling to manage your brand’s online reputation? Let us know your needs and we’ll step-in for help through our workshop programs." />
                <Workshops imgPath={img6} imgText="Search Engine Marketing Course" title="Search Engine Marketing" para="Is your near goal to train your team in search engine marketing? All you need to do is write to us and we’ll help you by arranging workshops on SEM programs." />
                <Workshops imgPath={img7} imgText="Search Engine Optimization Course" title="Search Engine Optimization" para="Does your team lack proficiency in search engine optimization? Write to us and we will assist you in meeting your goals through our workshops." />
                <Workshops imgPath={img8} imgText="Social Media Marketing Course" title="Social Media Marketing" para="Want to train your team to multiply the benefits of social media? Are you looking for the best programs to train your team in web analytics? Fill the workshop form and we’ll arrange programs that suit you the best." />
                <Workshops imgPath={img9} imgText="Web Analytics Course" title="Web Analytics" para="Are you looking for help to train your team in web analytics? Fill the workshop form and we’ll arrange one that suits you the best." />
                
            </div>
        </div>
        </div>
    </div>
  )
}
