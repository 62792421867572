import React from "react";

export default function Centers() {
  return (
    <div>
      <div className="careerz">
        <div className="contact-boxes">
          <div className="con-boxz">
            <h3>
              IIEDM
              <span className="subtitle">
                We sow Knowledge, Nurture Potential &amp; Brew Future.
              </span>
            </h3>
          </div>
          <div className="con-boxz">
            <h3>
              Dadar
              <span className="subtitle">
                Ground Floor, Hind Services Industrial Premises Co-operative
                Society Ltd, SVS Rd, Chandrakant Dhuru Wadi, Dadar, Mumbai,
                Maharashtra-400028
                <br />
                <a href="tel:+91-7738244304">+91-7738244304</a>
              </span>
            </h3>
          </div>
          <div className="con-boxz">
            <h3>
              Malad
              <span className="subtitle">
                230, Linkway Estate Next to Chincholi Fire Station, New Link Rd,
                Malad West, Mumbai, Maharashtra-400064
                <br />
                <a href="tel:+91-8879888513">+91-8879888513</a>
              </span>
            </h3>
          </div>
          <div className="con-boxz">
            <h3>
              Charni Road
              <span className="subtitle">
                1st Floor, 55, Ranchhod Bhuvan Bldg, Jagannath Shankar Seth Rd,
                near shiv krishna restaurant, Charni Road, Mumbai,
                Maharashtra-400004
                <br />
                <a href="tel:+91-8928272418">+91-8928272418</a>
              </span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}
