import React from 'react'

export default function Questions() {
  return (
    <div>
        <div class="FAQ-section">
        <div class="col">
          <p class="faq-header">(FAQ's) Your Questions, Our Solutions- Explained and Simplified</p>
          <div class="tabs" style={{fontSize:"18px"}}>
            <div class="tab">
              <input type="radio" id="rd15" name="rd"/>
              <label class="tab-label" for="rd15">What is UI design and UX design?</label>
              <div class="tab-content">
                <p>UI design stands for User Interface whereas UX stands for User experience. Both of these are extremely crucial to create a seamless product and need to be worked on together in sync. There are a multitude of <strong>UI UX design courses</strong> available that you can choose from and gain the necessary skills. Out of these courses available, you must do your research and only opt for the best UI UX design course in Mumbai or elsewhere.</p><br/>
                <p>At IIEDM- a prominent institute in Mumbai, we offer the finest UI UX design program that acquaints you with both the concepts thoroughly.</p>
              </div>
            </div>
            <div class="tab">
              <input type="radio" id="rd16" name="rd"/>
              <label class="tab-label" for="rd16">What role do UI/UX designers perform?</label>
              <div class="tab-content">
                <p>In brief, UI UX designers create a smooth experience for the customers by ensuring all the requirements of the customers are fulfilled. You will be designing trending and aesthetic designs that make the use of an app or website extremely easy, convenient and enjoyable. At IIEDM, a prominent institute in Mumbai, through our <strong>UI UX Design Course</strong>, we train you to understand user-centric issues and come up with effective solutions.</p>
              </div>
            </div>
            <div class="tab">
              <input type="radio" id="rd17" name="rd"/>
              <label class="tab-label" for="rd17">Who can join this course?</label>
              <div class="tab-content">
                <p> Anyone, irrespective of their profession and field can join our <strong>UI UX certification course</strong>. All one needs is the passion and interest to learn.
                </p>
                <ul>
                  <li>Graphic Designers</li>
                  <li>Web Designers / App Developers</li>
                  <li>Freelancers/Freshers</li>
                  <li>Students from any vertical</li>
                  <li>People from all the walks of life</li>
                </ul><br/>
                <p>At IIEDM, a leading institute and provider of the finest UI UX design course in Mumbai, we welcome everyone- a novice and professional to learn everything about UI UX.</p>
              </div>
            </div>
            <div class="tab">
              <input type="radio" id="rd18" name="rd"/>
              <label class="tab-label" for="rd18">Do I need to have any knowledge about UI UX to join the course?</label>
              <div class="tab-content">
                <p>All we can say is that it ‘Doesn’t matter’ because at IIEDM, a superior institute in Mumbai, our <strong>UI UX training</strong> offers a comprehensive learning experience that covers even the basics.</p>
              </div>
            </div>
            <div class="tab">
              <input type="radio" id="rd19" name="rd"/>
              <label class="tab-label" for="rd19">Is this a good course to opt for?</label>
              <div class="tab-content">
                <p>As websites and apps are on the rise, so is the demand for UI and UX designers in Mumbai and other cities. The demand is going to increase even higher as a majority of the businesses are moving online. With the right <strong>UI UX design course</strong>,  in Mumbai or anywhere else, you can gain the necessary skills and get an opportunity to work in renowned companies.</p>
              </div>
            </div>
            <div class="tab">
              <input type="radio" id="rd20" name="rd"/>
              <label class="tab-label" for="rd20"> Does UI/ UX require coding?</label>
              <div class="tab-content">
                <p>No, coding isn’t mandatory. However, having knowledge about it can be an advantage while learning about UI UX design.</p><br/>
                <p>At IIEDM, a leading institute in Mumbai, we offer a well-designed UI UX course. Through our course an individual gets to gain necessary knowledge and practical experience.</p>
              </div>
            </div>
            <div class="tab">
              <input type="radio" id="rd21" name="rd"/>
              <label class="tab-label" for="rd21">What are the job roles I can avail after completing the course?</label>
              <div class="tab-content">
                <p>After the completion of UI UX design course, you can avail entry level designer jobs. If you are already engaged in a creative industry, you can also opt for a senior role.
                </p><br/>
                <p>At IIEDM, the best institute in Mumbai, we let you write your story of success by offering you the right knowledge and experience through our UI UX design program.</p>
              </div>
            </div>
            <div class="tab">
              <input type="radio" id="rd22" name="rd"/>
              <label class="tab-label" for="rd22">Do I get a certificate?</label>
              <div class="tab-content">
                <p>Yes, you do get a certificate after the completion of the UX UI course. At IIEDM, we provide you with a certificate that not only signifies the completion of your course but also helps you avail good opportunities in the digital marketing space not just in Mumbai but elsewhere too.</p>
              </div>
            </div>
            <div class="tab">
              <input type="radio" id="rd23" name="rd"/>
              <label class="tab-label" for="rd23"> Does IIEDM Mumbai offer job placements?</label>
              <div class="tab-content">
                <p>In addition to offering the best UI UX design course in Mumbai, we being the leading institute in Mumbai also ensure to offer placements to our students at the best of the companies that can help them grow.</p>
              </div>
            </div>
    
          </div>
        </div>
    
    </div>
    </div>
  )
}
