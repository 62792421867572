import React from 'react'

export default function Stats() {
  return (
    <div>
        <div className="about-counter">
        <div className="wrapper">
            <div className="counter">
              <p className="timer count-title count-number">15000+</p>
               <p className="count-text ">Professionals Trained</p>
            </div>
        
            <div className="counter">
              <p className="timer count-title count-number">650+</p>
              <p className="count-text ">Batches Conducted</p>
            </div>
        
            <div className="counter">
              <p className="timer count-title count-number">90+</p>
              <p className="count-text ">Expert Faculty</p>
            </div>
        
            <div className="counter end">
              <p className="timer count-title count-number">10+</p>
              <p className="count-text ">Years of Experience</p>
            </div>
        </div>
    </div>

    </div>
  )
}
