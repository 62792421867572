import react from 'react'

const Advertisement = ()=>{
    return(
        <div>
            <div className="row third-fold pt-0">
    <div id="call-to">
      <div className="cia-title">
        <h2 style={{letterSpacing:"0.5px"}}>Join the Best Digital Marketing Courses with Placement from the <br/>
          Top Digital Marketing Institute in Mumbai, India.</h2>
      </div>

      <div className="call"><a className="enquire-btn button-pulse" href="tel:7738244304">Call Now!</a></div>
    </div>
  </div>
        </div>
    );
};

export default Advertisement;