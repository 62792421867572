import React from 'react'
import BackgroundImg from '../images_fl/corporate-hero.jpg'

export default function Head() {
  return (
    <div>
        <div className="aboutzz-hero-con" style={{padding:"20px"}}>
        <div className="aboutzz-hero">
            <div className="about-text" >
                <h1 className="large" style={{letterSpacing:"1px"}}>Corporate Training Program</h1>
                <span>Digital Marketing Corporate Training That Trains Your Employees To Deliver Excellence.</span>
                <div className="btn-hold"><a href="#enquiry">Enquire Now</a></div>
            </div>
        <div className="ab-img-box">
            <div className="imgs">
                <div className="bg-img" style={{backgroundImage: `url(${BackgroundImg})`}}></div>
                <div className="image-box-pattern">
                    <div className="img-pattern"></div>
                </div>
                <div className="img-circle-pattern"></div>
            </div>
        </div>
        </div>
    </div>
    </div>
  )
}
