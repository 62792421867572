import '../App.css'
import Navbar from '../components/Navbar/Navbar';
import Company from '../components/Companies_charni/Companies';
import Mid from '../components/Mid/Mid';
import Footer from '../components/Footer/Footer';
import MainCoursesHead from '../components/Courses/MainCoursesHead';
import CoursesComponent from '../components/Courses/CoursesComponent';

function Courses() {
  return (
    <div>
      <Navbar/>
      <MainCoursesHead/>
      <Company/>
      <CoursesComponent/>
      {/* <Mid phone="7738244304"/> */}
      <Footer/>
    </div>
  );
}

export default Courses;
