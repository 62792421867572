import react from 'react'

const Question=()=>{
    return(
        <div>
            <div className="FAQ-section">
    <div className="col">
      <p className="head">Frequently Asked Questions</p>
      <div className="tabs">
        <div className="tab">
          <input type="radio" id="rd1" name="rd"/>
          <label className="tab-label" for="rd1">What is Digital Marketing?</label>
          <div className="tab-content">
            <p>Before the internet advancements, people only used the traditional methods of advertising such as
              billboards, TV, etc. But today as more and more people are using the internet, the brands have also
              started advertising over the internet through various digital channels such as social media, search engine
              marketing, etc. This is known as digital marketing.</p><br/>
            <p>IIEDM, a leading institute in Mumbai offers the finest digital marketing learning through the finest
              courses and training that helps to gain detailed information about the subject matter and help you ace it
              with practical knowledge.</p>
          </div>
        </div>
        <div className="tab">
          <input type="radio" id="rd2" name="rd"/>
          <label className="tab-label" for="rd2">Who can be a digital marketer?</label>
          <div className="tab-content">
            <p>Digital Marketing can be pursued by anyone and everyone who has a keen interest to work in a dynamic
              environment and is an internet enthusiast. Anyone who's looking for a career change and even fresh
              graduates can excel in this field with the right training.</p><br/>
            <p>At IIEDM, a trusted institute in Mumbai, you can avail the best digital marketing courses and training
              that can help you know the A to Z of the digital realm and how you can excel it.</p>
          </div>
        </div>
        <div className="tab">
          <input type="radio" id="rd3" name="rd"/>
          <label className="tab-label" for="rd3">What are the career options in digital marketing?</label>
          <div className="tab-content">
            <p>Here are a some of the options that you can consider for your career:
            </p>
            <ul>
              <li>Search Engine Optimization</li>
              <li>Search Engine Marketing</li>
              <li>Social Media Marketing</li>
              <li>Email Marketing</li>
              <li>Content Writing</li>
              <li>E-Commerce Marketing</li>
              <li>UI/UX</li>
              <li>Analytics</li>
              <li>Web Development</li>
              <li>Graphic Design</li>
            </ul><br/>
            <p>At IIEDM, a trusted institute in Mumbai we help to open doors of opportunities for enthusiasts through
              comprehensive digital marketing courses and training, and also through the help of placements.</p>
          </div>
        </div>
        <div className="tab">
          <input type="radio" id="rd4" name="rd"/>
          <label className="tab-label" for="rd4">Do you need to have a marketing background to pursue a career in digital
            marketing?</label>
          <div className="tab-content">
            <p>Digital Marketing can be pursued by anyone from any background, a lot of people looking out for a career
              change switch over to digital marketing because of how easy to learn and adaptable it is. IIEDM's
              courses,syllabus and training in Mumbai are designed in a way that is easy to understand for everyone no
              matter their background.</p>
          </div>
        </div>
        <div className="tab">
          <input type="radio" id="rd5" name="rd"/>
          <label className="tab-label" for="rd5">What are the benefits of taking up a course from IIEDM?</label>
          <div className="tab-content">
            <p>IIEDM has industry experts hired as teaching faculty. We have courses that teach you everything right
              from the basics to the advanced level. IIEDM is the only digital marketing institute in Mumbai backed by
              agency experience. We teach our students through live projects giving them hands-on practical experience
              during their training. 95% of our candidates are currently employed and work with some of the biggest
              brands in the world.</p>
          </div>
        </div>
        <div className="tab">
          <input type="radio" id="rd6" name="rd"/>
          <label className="tab-label" for="rd6">Does IIEDM offer job placement?</label>
          <div className="tab-content">
            <p>IIEDM- a trusted institute of digital marketing in Mumbai makes you an expert with placement offers in
              some of the amazing companies. We also prepare our students for interviews by helping them with their
              resumes and conducting mock interviews so they are well prepared and confident for their interviews. The
              digital marketing courses and training in Mumbai with placements offered by IIEDM help enthusiasts to get
              the finest jobs in the industry.</p>
          </div>
        </div>
        <div className="tab">
          <input type="radio" id="rd7" name="rd"/>
          <label className="tab-label" for="rd7">What makes IIEDM the best digital marketing institute in Mumbai?</label>
          <div className="tab-content">
            <p>Being in the industry for over 10 years, IIEDM is home to 90+ faculty members aka industry experts that
              have trained over 15000+ students. We have exceptional professionals on board, excellent digital marketing
              courses and profound placement partners that give you a complete digital experience. Well, we're awarded
              as the best "Digital Marketing Institute" by Indian Education Awards for a reason. Oh, we're also ISO
              9001:2015 Certified.
            </p>
          </div>
        </div>
        <div className="tab">
          <input type="radio" id="rd8" name="rd"/>
          <label className="tab-label" for="rd8">Is the digital marketing course offered purely theoretical?</label>
          <div className="tab-content">
            <p>We at IIEDM believe practice is the best teacher, our students are assigned live projects to work on
              during their training and gain significant experience while completing their courses. This practical
              training makes us the finest institution in Mumbai and all-around.</p><br/>
            <p>
              Searching for, a digital marketing course near you? IIEDM is your answer as you can gain exceptional
              knowledge at minimal digital marketing course fees.</p>
          </div>
        </div>
        <div className="tab">
          <input type="radio" id="rd9" name="rd"/>
          <label className="tab-label" for="rd9">Is there any assistance for Google Certifications?</label>
          <div className="tab-content">
            <p>Yes, we assist our students in clearing Google Certifications. IIEDM- a leading institute in Mumbai is
              backed by a Digital Marketing agency, The Brand Saloon, which is a Google Partner Agency.</p>
          </div>
        </div>

      </div>
    </div>

  </div>
        </div>
    );
};

export default Question;