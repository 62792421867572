import React from 'react'

export default function Career() {
  return (
    <div>
        <div className="careerz">
        <div className="careerz-box">
            <div className="careerz-box-con">
                <div className="careerz-box-fit">
                    <h3>Senior Python Developer</h3>
                    <div className="careerz-acco">
                        <div className="tabs">
                            <div className="tab box1">
                                <input checked type="radio" id="rd1" name="rd"/>
                                <label className="tab-label" for="rd1">
                                    Requirements
                                </label>
                                <div className="tab-content">
                                    <ul>
                                        <li>B.Tech./ B.E / MCA degree in Computer Science, Engineering or a related stream.</li>
                                        <li>3+ years of software development experience.</li>
                                        <li>3+ years of Python / Java development projects experience.
                                        </li>
                                        <li>Minimum of 4 live project roll outs.</li>
                                        <li>Experience with third-party libraries and APIs.</li>
                                        <li>In depth understanding and experience of either SDLC or PDLC.</li>
                                        <li>Good Communication Skills</li>
                                        <li>Team Player</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="tab">
                                <input type="radio" id="rd2" name="rd"/>
                                <label className="tab-label" for="rd2">
                                    What we Expect from you?
                                </label>
                                <div className="tab-content">
                                    <ul>
                                        <li>Design and build applications/ components using open source technology.</li>
                                        <li>Taking complete ownership of the deliveries assigned.</li>
                                        <li>Collaborate with cross-functional teams to define, design, and ship new features.</li>
                                        <li>Work with outside data sources and API's.</li>
                                        <li>Unit-test code for robustness, including edge cases, usability, and general reliability.</li>
                                        <li>Work on bug fixing and improving application performance.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="tab">
                                <input type="radio" id="rd3" name="rd"/>
                                <label className="tab-label" for="rd3">
                                    What you've got?
                                </label>
                                <div className="tab-content">
                                    <p>You'll be familiar with agile practices and have a highly technical background, comfortable discussing detailed technical aspects of system design and implementation, whilst remaining business driven. With 5+ years of systems analysis, technical analysis or business analysis experience, you'll have an expansive toolkit of communication techniques to enable shared, deep understanding of financial and technical concepts by diverse stakeholders with varying backgrounds and needs. In addition, you will have exposure to financial systems or accounting knowledge.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a href="" className="careez-btn">Request a Brochure</a>
                </div>               
            </div>
            <div className="careerz-box-con">
                <div className="careerz-box-fit">
                    <h3>Design Analyst</h3>
                    <div className="careerz-acco">
                        <div className="tabs">
                            <div className="tab">
                                <input type="radio" id="rd4" name="rd"/>
                                <label className="tab-label" for="rd4">
                                    Requirements
                                </label>
                                <div className="tab-content">
                                    <ul>
                                        <li>B.Tech./ B.E / MCA degree in Computer Science, Engineering or a related stream.</li>
                                        <li>3+ years of software development experience.</li>
                                        <li>3+ years of Python / Java development projects experience.
                                        </li>
                                        <li>Minimum of 4 live project roll outs.</li>
                                        <li>Experience with third-party libraries and APIs.</li>
                                        <li>In depth understanding and experience of either SDLC or PDLC.</li>
                                        <li>Good Communication Skills</li>
                                        <li>Team Player</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="tab">
                                <input type="radio" id="rd5" name="rd"/>
                                <label className="tab-label" for="rd5">
                                    What we Expect from you?
                                </label>
                                <div className="tab-content">
                                    <ul>
                                        <li>Design and build applications/ components using open source technology.</li>
                                        <li>Taking complete ownership of the deliveries assigned.</li>
                                        <li>Collaborate with cross-functional teams to define, design, and ship new features.</li>
                                        <li>Work with outside data sources and API's.</li>
                                        <li>Unit-test code for robustness, including edge cases, usability, and general reliability.</li>
                                        <li>Work on bug fixing and improving application performance.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="tab">
                                <input type="radio" id="rd6" name="rd"/>
                                <label className="tab-label" for="rd6">
                                    What you've got?
                                </label>
                                <div className="tab-content">
                                    <p>You'll be familiar with agile practices and have a highly technical background, comfortable discussing detailed technical aspects of system design and implementation, whilst remaining business driven. With 5+ years of systems analysis, technical analysis or business analysis experience, you'll have an expansive toolkit of communication techniques to enable shared, deep understanding of financial and technical concepts by diverse stakeholders with varying backgrounds and needs. In addition, you will have exposure to financial systems or accounting knowledge.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a href="" className="careez-btn">Request a Brochure</a>
                </div>               
            </div>
            <div className="careerz-box-con">
                <div className="careerz-box-fit">
                    <h3>Head of UX and Design</h3>
                    <div className="careerz-acco">
                        <div className="tabs">
                            <div className="tab">
                                <input type="radio" id="rd7" name="rd"/>
                                <label className="tab-label" for="rd7">
                                    Requirements
                                </label>
                                <div className="tab-content">
                                    <ul>
                                        <li>B.Tech./ B.E / MCA degree in Computer Science, Engineering or a related stream.</li>
                                        <li>3+ years of software development experience.</li>
                                        <li>3+ years of Python / Java development projects experience.
                                        </li>
                                        <li>Minimum of 4 live project roll outs.</li>
                                        <li>Experience with third-party libraries and APIs.</li>
                                        <li>In depth understanding and experience of either SDLC or PDLC.</li>
                                        <li>Good Communication Skills</li>
                                        <li>Team Player</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="tab">
                                <input type="radio" id="rd8" name="rd"/>
                                <label className="tab-label" for="rd8">
                                    What we Expect from you?
                                </label>
                                <div className="tab-content">
                                    <ul>
                                        <li>Design and build applications/ components using open source technology.</li>
                                        <li>Taking complete ownership of the deliveries assigned.</li>
                                        <li>Collaborate with cross-functional teams to define, design, and ship new features.</li>
                                        <li>Work with outside data sources and API's.</li>
                                        <li>Unit-test code for robustness, including edge cases, usability, and general reliability.</li>
                                        <li>Work on bug fixing and improving application performance.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="tab">
                                <input type="radio" id="rd9" name="rd"/>
                                <label className="tab-label" for="rd9">
                                    What you've got?
                                </label>
                                <div className="tab-content">
                                    <p>You'll be familiar with agile practices and have a highly technical background, comfortable discussing detailed technical aspects of system design and implementation, whilst remaining business driven. With 5+ years of systems analysis, technical analysis or business analysis experience, you'll have an expansive toolkit of communication techniques to enable shared, deep understanding of financial and technical concepts by diverse stakeholders with varying backgrounds and needs. In addition, you will have exposure to financial systems or accounting knowledge.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a href="" className="careez-btn">Request a Brochure</a>
                </div>               
            </div>
            <div className="careerz-box-con">
                <div className="careerz-box-fit">
                    <h3>Web & Visual Designer (Marketing)</h3>
                    <div className="careerz-acco">
                        <div className="tabs">
                            <div className="tab">
                                <input  type="radio" id="rd10" name="rd"/>
                                <label className="tab-label" for="rd10">
                                    Requirements
                                </label>
                                <div className="tab-content">
                                    <ul>
                                        <li>B.Tech./ B.E / MCA degree in Computer Science, Engineering or a related stream.</li>
                                        <li>3+ years of software development experience.</li>
                                        <li>3+ years of Python / Java development projects experience.
                                        </li>
                                        <li>Minimum of 4 live project roll outs.</li>
                                        <li>Experience with third-party libraries and APIs.</li>
                                        <li>In depth understanding and experience of either SDLC or PDLC.</li>
                                        <li>Good Communication Skills</li>
                                        <li>Team Player</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="tab">
                                <input type="radio" id="rd11" name="rd"/>
                                <label className="tab-label" for="rd11">
                                    What we Expect from you?
                                </label>
                                <div className="tab-content">
                                    <ul>
                                        <li>Design and build applications/ components using open source technology.</li>
                                        <li>Taking complete ownership of the deliveries assigned.</li>
                                        <li>Collaborate with cross-functional teams to define, design, and ship new features.</li>
                                        <li>Work with outside data sources and API's.</li>
                                        <li>Unit-test code for robustness, including edge cases, usability, and general reliability.</li>
                                        <li>Work on bug fixing and improving application performance.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="tab">
                                <input type="radio" id="rd12" name="rd"/>
                                <label className="tab-label" for="rd12">
                                    What you've got?
                                </label>
                                <div className="tab-content">
                                    <p>You'll be familiar with agile practices and have a highly technical background, comfortable discussing detailed technical aspects of system design and implementation, whilst remaining business driven. With 5+ years of systems analysis, technical analysis or business analysis experience, you'll have an expansive toolkit of communication techniques to enable shared, deep understanding of financial and technical concepts by diverse stakeholders with varying backgrounds and needs. In addition, you will have exposure to financial systems or accounting knowledge.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a href="" className="careez-btn">Request a Brochure</a>
                </div>               
            </div>
        </div>
    </div>
    </div>
  )
}
