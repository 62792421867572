import '../App.css'
import Navbar from '../components/Navbar/Navbar';
import Company from '../components/Companies_charni/Companies';
import Footer from '../components/Footer/Footer';
import Head from '../components/AboutUs/Head';
import Mid from '../components/AboutUs/Mid';
import Stats from '../components/AboutUs/Stats';
import Card from '../components/AboutUs/Card';



function AboutUs() {
  return (
    <div>
      <Navbar/>
      <Head/>
      <Company/>
      <Mid/>
      <Stats/>
      <Card/>
      <Footer/>
    </div>
  );
}

export default AboutUs;
