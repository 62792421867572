import React from 'react'
import image from '../images_fl/franchise-hero-2.jpg';

export default function Mid() {
  return (
    <div>
        <div className="about-des" style={{background: "#fafafa"}}>
        <div className="about-des-box">
            <div className="about-des-img" style={{backgroundImage: `url(${image})`}}></div>
            <div className="about-des-content">
                <h3>A Worthwhile Digital Marketing Franchise Opportunity For a Successful Entrepreneur of Tomorrow</h3>
                <p>Digital marketing has evolved since its inception to become an elixir of the virtual world that every young job seeker and professional/student craves to possess, hence the rapid rise of digital marketing coaching institutes. The growth graph of the industry has witnessed a magnificent spike and doesn’t seem to lower down any time soon. Today, it’s considered to be the ‘Golden Opportunity’ that everyone wishes to learn and acquire through a trusted coaching institute which is run by a professional of the industry. You can make that possible through capitalizing on the profitable education franchise business of a digital marketing coaching institute.</p>
                <p>During the uncertainties of the pandemic, Digital marketing coaching institute franchise is one of the successful and stable businesses that has thrived and conquered the minds of many considering the plethora of benefits digital marketing embodies. Since everyone and everything is virtual today, it serves as the best opportunity for an enthusiastic business entrepreneur to expand his/her horizon and invest in a renowned Digital marketing education and coaching institute business franchise in Mumbai, India that garners exceptional returns at lower risks.</p>
                <p>An Award-winning coaching institute for digital marketing education in Mumbai, India - IIEDM (a TBS venture) since its advent in 2011 has been creating strides of excellence in the industry. With extensive experience as a digital marketing coaching institute, exceptional standards of education, maestros of the industry as faculty and a surfeit of renowned placement partners, IIEDM poses as one of the finest low-cost education franchise business opportunities in Mumbai, India.</p>
                <div id="chooseUs" className="counter-wrapper franchise">
                    <div className="features frow">
                      <div className="fr-box">
                        <p className="numb">15000+</p>
                        <p className="numbp">PROFESSIONALS TRAINED</p>
                      </div>
                      <div className="fr-box">
                        <p className="numb">650+</p>
                        <p className="numbp">BATCHES CONDUCTED</p>
                      </div>
                      <div className="fr-box">
                        <p className="numb">90+</p>
                        <p className="numbp">Expert Faculty</p>
                      </div>
                      <div className="fr-box">
                        <p className="numb">10+</p>
                        <p className="numbp">Years of Experience</p>
                      </div>
                    </div>
                  </div>
            </div>
        </div>
    </div>
    </div>
  )
}
