import '../App.css';
import Navbar from '../components/Navbar/Navbar';
import Head from '../components/UI_UX_Courses/Head';
import Mid from '../components/Mid/Mid';
import Footer from '../components/Footer/Footer';
import Company from '../components/Companies_charni/Companies';
import Mid2 from '../components/UI_UX_Courses/Mid2';
import Award from '../components/Award/Award';
import Testimonial from '../components/Testimonials/Testimonials';
import Programs from '../components/UI_UX_Courses/Programs';
import Questions from '../components/UI_UX_Courses/Questions';

function CoorporateTraining() {
  return (
    <div>
      <Navbar/>
      <Head/>
      <Company/>
      <Mid phone="7738244304"/>
      <Mid2/>
      <Award/>
      <Testimonial/>
      <Programs/>
      <Questions/>
      <Footer/>
    </div>
  );
}

export default CoorporateTraining;
