import React from 'react'
import image from '../images_fl/contact-us-enq.jpg';

export default function Contact() {
  return (
    <div>
        <div className="careerz">
        <div className="enquiryz-box">
            <div className="bg-img" style={{backgroundImage: `url(${image})`}}></div>
            <div className="apply-form">
                Send us an Email
                <form action="contactpage_form.php" id="" name="" method="post" role="form">
                    <label for="name">Full name</label>
                    <input type="text" id="name" name="name" placeholder="Your Full Name" required/>
                    <label for="email">Email <small>*</small></label>
                    <input type="email" id="email" name="email" value="" placeholder="Your Email" className="required email sm-form-control"/>
                    <label for="phone">Phone <small>*</small></label>
                    <input type="number" name="mobile" id="mobile" value="" placeholder="Mobile Number" size="22" tabindex="5" className="sm-form-control required"/>
                    <label for="courses">Courses <small>*</small></label>
                        <select name="courses" id="courses" tabindex="9" className="sm-form-control required">
                            
										<option value="">-- Take your Pick --</option>

										<option value="Advance Program (APDM)">Advance Program (APDM)</option>

										<option value="Certificate Program (DMCP)">Certificate Program (DMCP)</option>

										<option value="Certification in SEO">Certification in SEO</option>

										<option value="Certification in SMO">Certification in SMO</option>

                                        <option value="Certification in SEM/PPC">Certification in SEM/PPC</option>

										<option value="Certification in Analytics">Certification in Analytics</option>

                                        <option value="Certification in Content Writing">Certification in Content Writing</option>

                                        <option value="Corporate Training">Corporate Training</option>

                                        <option value="TBS Franchise">TBS Franchise</option>
                        </select>
                    <label for="message">Message <small>*</small></label>
                    <textarea name="message" id="message" rows="6" tabindex="11" className="sm-form-control required"></textarea>
                    <button type="submit" id="submit" name="submit">Send</button>
                </form>
            </div>
        </div>

        <div className="contact-boxes">
            <div className="con-boxz"><h3>IIEDM<span className="subtitle">We sow Knowledge, Nurture Potential &amp; Brew Future.</span></h3></div>
            <div className="con-boxz"><h3>Our Headquarter<span className="subtitle">Ground Floor, Hind Services Industrial Premises Co-operative Society Ltd, SVS Rd, Chandrakant Dhuru Wadi, Dadar, Mumbai, Maharashtra-400028</span></h3></div>
            <div className="con-boxz"><h3>Speak to Us<span className="subtitle"><a href="tel:+91-7738244304">+91-7738244304</a><br/><a href="tel:+91-9136279810">+91-9136279810</a></span></h3></div>
            <div className="con-boxz"><h3>Write To Us<span className="subtitle"><a href="mailto:contact@iiedm.com">contact@iiedm.com</a></span></h3></div>
        </div>
    </div>
    </div>
  )
}
