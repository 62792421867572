import React from 'react'

export default function Questions() {
  return (
    <div>
        <div className="FAQ-section">
        <div className="col">
          <p className="faq-header">(FAQ's) Your Questions, Our Solutions- Explained and Simplified</p>
          <div className="tabs">
            <div className="tab">
              <input type="radio" id="rd11" name="rd"/>
              <label className="tab-label" for="rd11"> What is Search Engine Marketing?</label>
              <div className="tab-content">
                <p>It is a digital marketing tool that helps in increasing a website’s visibility ranking it higher on the search engine results mostly through paid advertising. It is one of the most effective strategies to get leads on a website. 
                </p><br/>
                <p>IIEDM- a leading institute in Mumbai offers the finest Google ads course and training in Mumbai that helps enthusiasts to learn from the basics to the details of paid marketing.</p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd12" name="rd"/>
              <label className="tab-label" for="rd12"> Who can opt for Search Engine Marketing? 
            </label>
              <div className="tab-content">
                <p>If you're the one who is tempted to know about the search engine's working process, and how businesses grow through digital marketing, you're the right one to go for search engine marketing. Our Google ads certification course and <strong>SEM training in Mumbai</strong> makes you master SEM techniques, clearing your basics to teach you the advanced methodologies, no matter what background you belong to. Through IIEDM- a credible institute in Mumbai, become the best at SEM. </p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd13" name="rd"/>
              <label className="tab-label" for="rd13">Do I need to have a knowledge of SEM to take up this course? 
            </label>
              <div className="tab-content">
                <p> Only knowing what it is would be enough, the rest of our syllabus will help you learn. <strong>SEM and PPC training in Mumbai</strong> offered by IIEDM- an award-winning institute will help you know everything right from the beginning to the advanced levels covering every aspect of the subject through the comprehensive Google Ads certification course.  
                </p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd14" name="rd"/>
              <label className="tab-label" for="rd14"> What role does an SEM expert play?
            </label>
              <div className="tab-content">
                <p>An SEM expert designs paid campaigns, writes ad copy for Google AdWords, maintains the campaign and applies strategies to rank the website higher through paid marketing. At IIEDM- a trusted institute in Mumbai, learn the A to Z of search engine marketing through a well-designed certification course and training in Mumbai. </p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd15" name="rd"/>
              <label className="tab-label" for="rd15">Do you need to have a marketing background to learn Search Engine Marketing? 
            </label>
              <div className="tab-content">
                <p>NO! People from different educational backgrounds, different career areas and even fresh graduates can take up our SEM and <strong>Google AdWords certification course and training</strong>.   It teaches you everything right from the beginning and makes you a Search engine marketing expert. At IIEDM- one of the best institutes in Mumbai, we welcome people with and without experience to transform them into maestros of the industry.</p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd16" name="rd"/>
              <label className="tab-label" for="rd16">Why is taking up an SEM course important? 
            </label>
              <div className="tab-content">
                <p>Today, everyone has started relying on search engines and the internet to know about everything. Therefore, It is extremely essential to rank higher and be visible on the search engines in order to get new leads and expand any business. This has created a demand for SEM experts in the market. Hence it is extremely beneficial from your career’s point of view to take up an <strong>Google Adwords certification course and Google ads training</strong> that explains every aspect of the subject with the relevant practical training, just like IIEDM  does.</p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd17" name="rd"/>
              <label className="tab-label" for="rd17">Is the SEM course offered by IIEDM theoretical?</label>
              <div className="tab-content">
                <p>IIEDM’s Google Ads certification course and training, unlike the other <strong>Search Engine Marketing courses in Mumbai</strong> trains you with a practical approach towards the industry. Through live classes, assignments and application-based activities, our certification course makes you learn the techniques of SEM in a very interesting yet informative manner. 
                </p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd18" name="rd"/>
              <label className="tab-label" for="rd18">Is this a certification course? 
            </label>
              <div className="tab-content">
                Yes! After completing our all-inclusive, SEM, paid marketing, and <strong>Google Adwords certification course</strong>and training, you are awarded IIEDM’s certification along with a Google certificate. With IIEDM, a prominent institute in Mumbai, you don’t just get the necessary knowledge but also valuable certification.
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd19" name="rd"/>
              <label className="tab-label" for="rd19">Do IIEDM digital marketing and SEM institute offer job placements? </label>
              <div className="tab-content">
                <p>Yes, with IIEDM - the leading institute in Mumbai, you don't just get access to the best <strong>Google Ads certification course</strong> and training in Mumbai, but also get to learn from the industry experts and get 100% placements. 
                </p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd20" name="rd"/>
              <label className="tab-label" for="rd20">Why should I learn from IIEDM?</label>
              <div className="tab-content">
                <p>Our passion since inception has always been to offer nothing but the best that has landed us the Best Digital Marketing Institute Award. We strive to get you the finest faculty and the best practical and theoretical Google Ad certification course and training that allows you to step into the industry with experience. Being one of the best institutes in Mumbai, we also offer exciting placement opportunities.</p>
              </div>
            </div>
    
          </div>
        </div>
    
    </div>
    </div>
  )
}
