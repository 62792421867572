import React from 'react'

export default function Call(props) {
  return (
    <div>
          <div className="row third-fold call mb-0">
                <div id="call-to">
                    <div className="cia-title">
                        <p className="call-head">{props.text1}</p>
                        <p>{props.text2}</p>
                    </div>
                
                    <div className="call"><a className="enquire-btn button-pulse" href= {`tel:${props.phone}`}>Call Now!</a></div>
                </div>
            </div>
    </div>
  )
}
