import react from 'react'

const Media = ()=>{
    return(
        <div>
            <div id="media-footprints" className="row fifth-fold">
    <div>
      <div className="media-text">
        <p className="head">Media Footprints</p>
        <p>Not just our clients, students and education and business partners,
          even the media has showered love on us from time to time.</p>
      </div>
      <div className="media-img">

        <img loading="lazy" src={require("../images_fl/inmedia.webp")} alt="Best Digital Marketing Institute on media"/>


      </div>
    </div>
  </div>
        </div>
    );
};

export default Media;