import React from 'react'
import BackgroundImg from '../images_fl/best-courses-heor.webp'
export default function MainCoursesHead() {
  return (
    <div>
        <div className="aboutzz-hero-con">
        <div className="aboutzz-hero">
            <div className="about-text">
                <h1>Courses</h1>
                <img loading="lazy"src={require("../images_fl/trophy.png")} width="80px" height="80px" alt="IIEDM Award Winning Training Institute"/>
                <span><p style={{fontSize:"30px", fontWeight:"bold"}}>Check Out the Different Courses Offered by IIEDM - an Award Winning Institute.</p></span>
                
            </div>
        <div className="ab-img-box">
            <div className="imgs">
                <div className="bg-img" style={{backgroundImage: `url(${BackgroundImg})`}}></div>
                <div className="image-box-pattern">
                    <div className="img-pattern"></div>
                </div>
                <div className="img-circle-pattern"></div>
            </div>
        </div>
        </div>
    </div>
    </div>
  )
}
