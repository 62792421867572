import React from 'react'

export default function Program() {
  return (
    <div>
        <div className="enq-pro">
        <div className="programs">
            <div className="title">
                <p className="main-title">Other Programs</p>
            </div>
            <div className="pro-box">

            <a href="https://iiedm.com/best-digital-marketing-courses-iiedm-tbs.php">Advanced Digital Marketing Certification Course(ADMCC)</a>
            <a href="https://iiedm.com/best-digital-marketing-courses-iiedm-tbs.php">Certification Course in Digital Marketing(CCDM)</a>
            <a href="https://iiedm.com/certification-course-in-seo-mumbai.php">Certification Course in SEO</a>
            <a href="https://iiedm.com/certification-course-in-social-media-marketing-mumbai.php">Certification Course in Social Media Marketing</a>
            <a href="https://iiedm.com/certification-course-in-ppc-adwords-sem-mumbai.php">Certification Course in PPC-Adwords-SEM</a>
            <a href="https://iiedm.com/ui-ux-design-course-mumbai.php">UI/UX Design courses</a>

            </div>
        </div>
        <div id="contact" className="secfold-box form-con">
			<div className="form-bg">
				<p className="main-title">Enquire Now</p>
				<div>
					<form id="lead-form" method="post" name="lead-form" action="contentenquiry_form.php">
						<input type="text" name="name" placeholder="Name *" required=""/>
						<input type="email" name="email" placeholder="Email *" required=""/>
						<input type="number" name="mobile" pattern="[0-9]" placeholder="Mobile *" required=""/>
						<select name="center" id="center" className="placeholder field" required="">
							<option value="">Select Center</option>
							<option value="Dadar">Dadar</option>
							<option value="Malad">Malad</option>
              <option value="Dombivli">Dombivli</option>
							<option value="Charni Road">Charni Road</option>
						</select>
						<input type="text" name="message" placeholder="Message *" required=""/>
						<input type="submit" id="myButton" value="ENQUIRE NOW"/>
					</form>
				</div>
				<div className="socialbtns">
					<div className="eqC1"><a href="tel:7738244304"> <img loading="lazy"
						  src={require("../images_fl/phone-icon.webp")} width="35" height="35" alt="Call us Now!"/>7738244304</a></div>
					<div className="eqC2"><a
						href="https://api.whatsapp.com/send?phone=+917738244304&text=Hi, I would like to know more about Digital Marketing Courses offered by IIEDM."
						target="_blank">
						<img loading="lazy" width="28" height="28" src={require("../images_fl/whatsapp-icon.webp")} alt="Whatsapp us Now!"/>WhatsApp</a></div>
				  </div>
  
			  </div>
  
		</div>
    </div>
    </div>
  )
}
