import React from 'react'

export default function Mid() {
  return (
    <div>
        <div className="about-des">
        <div className="about-des-box">
            <div className="about-des-img"></div>
            <div className="about-des-content">
                <p style={{fontSize:'25px', fontWeight:'bold'}}>Be a part of TBS family - Award winning digital marketing training institute.</p>
                
                <p>With growing demand for digital marketers, comes the dearth of industry-trained professionals who can meet these needs. In 2011, it was to bridge this gap between that TBS- Digital Marketing Training Institute was founded with a vocation to train and educate the aspirants about the intricacies of Digital Marketing. To give our students the best learning experience, we root our teaching methods in a highly practical approach. We have reputed industry professionals as faculty who takes the students through live projects and case studies to compile a worthwhile experience for them.</p>
                <p>So, welcome to TBS. We're an award winning digital marketing institute where we are constantly looking forward to having keen, youthful, seeking candidates on board. Together, we could explore the futuristic side of marketing and make a stimulating prospect out of it.</p>
            </div>
        </div>
    </div>
    </div>
  )
}
