import react from 'react'

const Header = (props)=>{
    return(
        <div>
            <div className="thankyou malad"  style={{paddingTop:"15px"}}>
                <div className="container">
                    <div className="text-con">
                        <div className="ad-text" >
                            <h1 style={{color:"white", fontSize:"30px"}}>Digital Marketing Certification Courses in {props.location} for Students,Working Professionals & Entrepreneurs!</h1>
                            <h1 className="sub-header">Learn Digital Marketing from the Best digital marketing institute at {props.location}- <strong>IIEDM by TBS.</strong></h1>
                            <ul>
                                <li>Get trained and mentored by<strong> Industry Expert Professionals</strong></li>
                                <li><strong>100% Job Assistance</strong>
                                </li>
                                <li><strong>International Presence & knowledge Partners</strong></li>
            
                                <li>Institute backed by <strong>Google Partner Agency</strong></li>
                                <li>Awarded as the <strong>Best Institute at 5th Indian Education Awards</strong> </li>
                            </ul>
                            <a href="#contact" className="blue-button">Request a Brochure</a> <a href="tel:{props.phone}" className="blue-button syl">Call:{props.phone}</a>
            
                        </div>
            
                    </div>
                    <div className="bg-img"></div>
                </div>
            </div>
        </div>
    );
};

export default Header;