import React from "react";

export default function Questions() {
  return (
    <div>
      <div className="FAQ-section">
        <div className="col">
          <p className="faq-header">
            (FAQ’s) Your Questions, Our Solutions- Explained and Simplified
          </p>
          <div className="tabs">
            <div className="tab">
              <input type="radio" id="rd6" name="rd" />
              <label className="tab-label" for="rd6">
                What is SEO?
              </label>
              <div className="tab-content">
                <p>
                  Search Engine Optimisation in simple terms refers to enhancing
                  a site’s visibility on the search engines. In this digital
                  age, everyone strives to stay on top of their game and Search
                  Engine Optimization contributes to this significantly.
                  Considering the importance that SEO offers to the digital
                  realm, it has now become a ‘must-have’ of every digital plan.
                </p>
                <br />
                <p>
                  IIEDM- a leading digital marketing institute in Mumbai offers
                  you detailed knowledge through the certification SEO course
                  and training in Mumbai that acquaints you with everything
                  related to SEO.
                </p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd7" name="rd" />
              <label className="tab-label" for="rd7">
                Who can learn SEO?
              </label>
              <div className="tab-content">
                <p>
                  Search Engine Optimization is a field that is open for one and
                  all. Since today everyone and everything is online, people
                  from all backgrounds for eg. recent graduates from any field,
                  marketing professionals, freelancers and those who wish to
                  explore a new career opportunity can learn and excel in SEO
                  with the right knowledge and techniques.
                </p>
                <br />
                <p>
                  At IIEDM- a trusted institute in Mumbai, a certification
                  course and training in SEO is created considering everyone,
                  which fits every individual’s need and pocket.
                </p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd8" name="rd" />
              <label className="tab-label" for="rd8">
                What are the career options after completing the SEO course?
              </label>
              <div className="tab-content">
                <p>
                  {" "}
                  The career options that you can opt for after completing our
                  Search Engine Optimization certification course are as
                  follows:
                </p>
                <ul>
                  <li>SEO Specialist</li>
                  <li>SEO Manager</li>
                  <li>SEO Executive</li>
                  <li>SEO Project Manager</li>
                  <li>SEO Consultant</li>
                </ul>
                <br />
                <p>
                  IIEDM- one of the best institutes in Mumbai offers the finest
                  SEO certification course and training that opens doors to
                  several opportunities on the completion of the course.
                </p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd9" name="rd" />
              <label className="tab-label" for="rd9">
                Do you need to have a marketing background to pursue a career in
                SEO?
              </label>
              <div className="tab-content">
                <p>
                  The simple answer to this is ‘No’. SEO is just like water, it
                  can fit into the ‘skill container’ of an individual belonging
                  to any profession. At IIEDM- a credible institute in Mumbai ,
                  through our elaborate SEO certification course and training,
                  we ensure to offer not just advanced knowledge but the basics
                  too so that every enthusiast can become well acquainted with
                  Search engine optimization.
                </p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd10" name="rd" />
              <label className="tab-label" for="rd10">
                What are the benefits of doing an SEO course from IIEDM?
              </label>
              <div className="tab-content">
                <p>
                  Backed by one of the best digital marketing agencies in
                  Mumbai, The Brand Saloon and having the finest experts of the
                  industry, IIEDM- a leading institute in Mumbai offers
                  excellent certification courses and training that cater right
                  from beginners to professionals. The knowledge is enhanced via
                  live projects that give Search engine Optimization enthusiasts
                  practical knowledge and experience. This has enabled 95% of
                  our candidates to avail an opportunity in renowned companies.
                  IIEDM is an institute that is not just known to offer the
                  finest SEO certification course and training in Mumbai but
                  also provides a comprehensive digital learning experience.
                </p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd11" name="rd" />
              <label className="tab-label" for="rd11">
                Does IIEDM offer Job Placements?
              </label>
              <div className="tab-content">
                <p>
                  Having connections with over 100+ profound companies, we at
                  IIEDM- a promising institute in Mumbai, ensure to get our
                  students 100% job placements. In addition to the SEO
                  certification course and training, our institute prepares the
                  students before they step into the digital professional world
                  by working on their resume and training them comprehensively.
                  We also conduct mock interviews for them to be fully prepared
                  and to nail every interview they appear for.
                </p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd12" name="rd" />
              <label className="tab-label" for="rd12">
                What makes IIEDM the best SEO institute in Mumbai?
              </label>
              <div className="tab-content">
                <p>
                  Being in the industry for over 10 years, IIEDM- an experienced
                  digital marketing institute in Mumbai is home to 90+ faculty
                  members aka industry experts that have trained over 15000+
                  students. We have the best professionals on board, an
                  excellent certification course- training and profound
                  placement partners that give you a complete Search engine
                  Optimization experience. Well, we’re awarded as the best
                  "Digital Marketing Institute" by Indian Education Awards for a
                  reason. Oh, we’re also ISO 9001:2015 Certified.
                </p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd13" name="rd" />
              <label className="tab-label" for="rd13">
                Is the SEO course offered by IIEDM Mumbai purely theoretical?
              </label>
              <div className="tab-content">
                <p>
                  At IIEDM- a prominent institute in Mumbai, we strongly follow
                  the concept of ‘All theory and no practice makes a student a
                  one-sided learner’. At IIDEM- our SEO certification course and
                  training consists of not just words but practical experience
                  too because we believe practice is the best teacher. Our
                  students are assigned challenging live projects that give them
                  first-hand experience and allows them to grow as a
                  professional even before entering the digital realm.
                </p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd14" name="rd" />
              <label className="tab-label" for="rd14">
                Will I get a Certificate for the SEO course?
              </label>
              <div className="tab-content">
                <p>
                  Yes, being a leading institute, we provide you with a
                  certificate after the successful completion of the SEO
                  certification course and training.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
