import React from 'react'

export default function Call() {
  return (
    <div>
        <div className="row third-fold pt-0">
            <div id="call-to">
            <div className="cia-title">
                <h1 style={{letterSpacing:"1px"}}>Join the Best Digital Marketing Courses with placements from the <br/>
                Top Digital Marketing Institute in Mumbai, India.</h1>
            </div>
                <div className="call"><a className="enquire-btn button-pulse" href="tel:7738244304">Call Now!</a></div>
            </div>
        </div>
    </div>
  )
}
