import React from 'react'

export default function Questions() {
  return (
    <div>
        <div className="FAQ-section">
            <div className="col">
                <p className="head">Frequently Asked Questions</p>
                <div className="tabs" style={{fontSize:"18px"}}>
                    <div className="tab">
                    <input type="radio" id="rd1" name="rd"/>
                    <label className="tab-label" for="rd1"> What are the few digital skills that my team needs to ace?</label>
                    <div className="tab-content">
                        <p>It all depends on your business requirements and employees. The popular programs that several corporates ask for are corporate training in SEO, Social Media Marketing, Google Analytics and more. Based on your needs and your employee’s skills, we design bespoke training programs exclusively for your corporate by conducting workshops.</p><br/>
                        <p>IIEDM- a leading digital institute offers comprehensive knowledge through advanced to basic programs in corporate training workshops that acquaint employees with the necessary knowledge that helps them to improve their performance and make more profits.</p>
                    </div>
                    </div>
                    <div className="tab">
                    <input type="radio" id="rd2" name="rd"/>
                    <label className="tab-label" for="rd2">We require a custom program for our industry. Will you be able to design one?</label>
                    <div className="tab-content">
                        <p>Conducting corporate digital training workshops for years, we understand that every industry is different and so should be the training programs designed for them. Therefore, as per your organization’s need, we offer customized corporate training workshops that sync with your and the industry’s requirements.</p><br/>
                        <p>IIEDM is an institute that helps foster your company’s productivity by holding customized corporate digital training workshops in SMM, SEO, content writing, Google Analytics and other programs that help employees to gain essential knowledge for a particular subject.</p>
                    </div>
                    </div>
                    <div className="tab">
                    <input type="radio" id="rd3" name="rd"/>
                    <label className="tab-label" for="rd3">Does your digital marketing corporate training programs offer certificates? How will it be beneficial for the employees?</label>
                    <div className="tab-content">
                        <p>Be it corporate training in content writing or in Google Analytics, etc, we offer certificates after the completion of the programs in our workshops. The certificate that you get on attending this workshop can signify that your employees are highly skilled and are industry-ready.

                        </p>
                        
                    </div>
                    </div>
                    <div className="tab">
                    <input type="radio" id="rd4" name="rd"/>
                    <label className="tab-label" for="rd4">Is corporate training in Social Media Marketing and other programs only for freshers?
                    </label>
                    <div className="tab-content">
                        <p>No, not just workshops for corporate training in programs like SMM but also those for corporate training in Google Analytics, content writing, SEO, etc are accessible to every employee of an organization irrespective of his/her position.</p>
                    </div>
                    </div>
                    <div className="tab">
                    <input type="radio" id="rd5" name="rd"/>
                    <label className="tab-label" for="rd5">Do you only offer advanced training programs in digital marketing for corporates?</label>
                    <div className="tab-content">
                        <p>From basic corporate training to advanced, we cover all aspects and ensure that we cater to every industry’s requirement and aim to offer the latest knowledge and skills through the programs in our corporate workshops.</p>
                    </div>
                    </div>
                    

                </div>
            </div>

        </div>
    </div>
  )
}
