import React from "react";

export default function Mid2() {
  return (
    <div>
      <div className="ad-modules content-hero">
        <div className="header">
          <h3>
            A Content Writing Course in Mumbai That Helps You Create Magic With
            Words{" "}
          </h3>
          <p>
            Acquire the skills to write content that is 'bookmarked' by many and
            also get access to career opportunities, plenty!
          </p>
        </div>
        <div className="module-boxes">
          <div className="apdm-box">
            <div className="title">
              <h3>
                Content Writing Certification Course in Mumbai that aids in
                setting your own career course!
              </h3>
            </div>
            <div className="content">
              <p>
                <span className="accented">
                  Content is no longer just the King, it's the pawn, the bishop,
                  the knight and the Everything! At IIEDM, we arm you with the
                  best content strategies and techniques that make every move
                  you take a 'Checkmate'. Being one of the experienced, reputed,
                  trusted and{" "}
                  <strong>best content writing institutes in Mumbai</strong>, we
                  get you faculty that hails from the industry that provides you
                  in-depth knowledge and puts in efforts for you to wear the
                  cloak of excellence.
                </span>
              </p>
              <p>
                Our course is an experience that takes you into the mystical
                land of words and allows you to explore the different forms of
                writing. For several platforms and for multiple services, with
                essential knowledge and hands-on experience, we teach you the
                power to write content that compels and converts readers into
                action-takers through our courses in Mumbai.
              </p>
              <p>
                We're an <strong>Award-Winning Institute in Mumbai</strong>,
                backed by agency experience that offers you exemplary career
                opportunities through placements that take you to the best
                places in the industry. And with our certification course, we
                equip you with the skills that leave you and the audience
                content with the content you create. You see what we just did
                there? That's called wordplay, you can learn it too. Come on
                board today!
                <p>Tags:</p>
                <h4>
                  Content Writing courses in Mumbai, Best Content Writing
                  courses in Mumbai, Certification course in Content Writing,
                  Content Writing courses with placements, Learn Creative
                  Writing
                </h4>
              </p>
            </div>
            <div className="tags">
              <a className="blue-button" href="#contact">
                Request a Brochure
              </a>
            </div>
          </div>
          <div id="syllabus" className="modules-box">
            <div className="title">
              <p>Modules Covered</p>
            </div>
            <div className="ad-accordian">
              <div className="tabs">
                <div className="tab">
                  <input checked type="radio" id="rd1" name="rd" />
                  <label className="tab-label" for="rd1">
                    <p>The Art Of Writing</p>
                  </label>
                  <div className="tab-content">
                    <p>Essentials of writing & storytelling</p>
                    <ul>
                      <li>what is storytelling</li>
                      <li>why is it important</li>
                      <li>what makes a good story and a bad one</li>
                      <li>basic rules of grammar and language</li>
                      <li>understanding 'tone of voice'</li>
                    </ul>
                  </div>
                </div>
                <div className="tab">
                  <input type="radio" id="rd2" name="rd" />
                  <label className="tab-label" for="rd2">
                    <p>Understanding The Reader </p>
                  </label>
                  <div className="tab-content">
                    <p>The psychology of writing</p>
                    <ul>
                      <li>
                        basic concepts of psychology (with regards to content
                        writing and marketing)
                      </li>
                      <li>understanding how the mind reacts to writing</li>
                    </ul>
                    <p style={{marginTop: '15px'}}>
                      Applying psychology to writing
                    </p>
                    <ul>
                      <li>how to use this knowledge to influence the reader</li>
                      <li>what readers like to read (or see/hear)</li>
                    </ul>
                  </div>
                </div>
                <div className="tab">
                  <input type="radio" id="rd3" name="rd" />
                  <label className="tab-label" for="rd3">
                    <p>Weaving words For The Digital Medium </p>
                  </label>
                  <div className="tab-content">
                    <ul>
                      <li>how to write for digital medium</li>
                      <li>what makes writing for digital different</li>
                      <li>copywriting for the digital medium</li>
                      <li>Technical and Creative Writing</li>
                    </ul>
                  </div>
                </div>
                <div className="tab">
                  <input type="radio" id="rd4" name="rd" />
                  <label className="tab-label" for="rd4">
                    <p>Different Types of Writing</p>
                  </label>
                  <div className="tab-content">
                    <ul>
                      <li>writing for websites</li>
                      <li>writing for blogs (reviews/UGC etc.)</li>
                      <li>
                        writing for social media marketing(Facebook / twitter
                        etc.)
                      </li>
                      <li>Writing for search engines</li>
                      <li>Web copywriting</li>
                      <li>writing for banners/stamp ads</li>
                    </ul>
                  </div>
                </div>
                <div className="tab">
                  <input type="radio" id="rd5" name="rd" />
                  <label className="tab-label" for="rd5">
                    <p>After You Write</p>
                  </label>
                  <div className="tab-content">
                    <ul>
                      <li>Proof Reading</li>
                      <li>Editing</li>
                      <li>Rewriting</li>
                    </ul>
                  </div>
                </div>

                <div className="tab">
                  <input type="radio" id="rda5" name="rd" />
                  <label className="tab-label" for="rda5">
                    <p>Interview Preparation / Freelancing</p>
                  </label>
                  <div className="tab-content">
                    <ul>
                      <li>Proof Reading</li>
                      <li>Editing</li>
                      <li>Rewriting</li>
                    </ul>
                  </div>
                </div>
                <div className="tab">
                  <input type="radio" id="rd6" name="rd" />
                  <label for="rd6" className="tab-close">
                    Close others &times;
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
