import react from 'react'
import {Helmet} from "react-helmet";


const Header = ()=>{
    return(
        <div>
          <Helmet>
        <script src="./script.js" type="text/javascript" />
      </Helmet>
            <div className="carousel">
            <div className="carousel-inner">
              <div className="carousel-item">
                <div className="slider-inner">
                  <div className="slider-inner-col">
                    <div>
                      <div><img src={require("../images_fl/amity-university.webp")} alt="PG in Digital Marketing"/></div>
                      <br />
                      <h2>Amity University Approved Executive Development Program In <span style={{color:'#30a0fe'}}>Digital
                          Marketing!</span></h2>
                      <div className="slider-ctas">
                        <a href="#courses">Course Details</a>
                        <a href="#contact">Request Brochure</a>
                      </div>
                      <br />
                      <img src={require("../images_fl/slide1usp.jpg")} className="slider-img-descp" alt="USPs"/>
                    </div>
                  </div>
                  <div className="slider-inner-col">
                    <div className="slider-img-box">
                      <img src={require("../images_fl/1-1.webp")} alt="Post Graduate Program In Digital Marketing- IIEDM"/>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- slide1 --> */}

              <div className="carousel-item">
                <div className="slider-inner">
                  <div className="slider-inner-col">
                    <div>
                      <div><img src={require("../images_fl/hat.png")} alt="PG in Digital Marketing"/></div>
                      <h2>MBA-Level Post Graduate Program In <span style={{color:'#30a0fe'}}>Digital Marketing</span></h2>
                      <p style={{paddingTop:'20px'}}>Our certification is in Alignment with <img src={require("../images_fl/certificate.png")}
                          alt="Post graduation Program in Digital Marketing"/></p>
                      <div className="slider-ctas">
                        <a href="#courses">Course Details</a>
                        <a href="#contact">Request Brochure</a>
                      </div>
                      <br />
                      <img src={require("../images_fl/slide1usp.jpg")} className="slider-img-descp" alt="USPs"/>
                    </div>
                  </div>
                  <div className="slider-inner-col">
                    <div className="slider-img-box">
                      <img src={require("../images_fl/1.webp")} alt="Post Graduate Program In Digital Marketing- IIEDM"/>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- slide2 --> */}


              <div className="carousel-item">
                <div className="slider-inner">
                  <div className="slider-inner-col">
                    <div>
                      <div><img src={require("../images_fl/award.png")} alt="IIEDM Awards"/> </div>
                      <h2>Learn Digital Marketing from an <span style={{color:'#3E9430'}}>Award Winning</span> Institute!</h2>
                      <img src={require("../images_fl/awardssmall.jpg")} className="slider-img-descp" alt="awards won by IIEDM"/>
                      <p style={{fontSize:'18px'}} className="desk-img">#1 MBA level PG in Digital Marketing course from an Award
                        winning Digital Marketing Institute.</p>
                      <div className="slider-ctas">
                        <a href="#contact">Request Brochure</a>
                      </div>
                    </div>
                  </div>
                  <div className="slider-inner-col">
                    <div className="slider-img-box"><img src={require("../images_fl/3.webp")}
                        alt="Award winning Digital Marketing Institute - IIEDM"/> </div>
                  </div>
                </div>
              </div>

              {/* <!-- slide3 --> */}
              <div className="carousel-item">
                <div className="slider-inner">
                  <div className="slider-inner-col">
                    <div>
                      <div><img src={require("../images_fl/global.png")} alt="Proven Global Standard training"/></div>
                      <h2>Proven <span style={{color:'#FF3738'}}>Global Standard </span>In Digital Marketing Training</h2>
                      <br />
                      <p style={{fontSize:'22px'}}>Our programs are developed with our extended team of leading global marketers,
                        so they are built and validated by industry, for industry.</p>
                        <br />
                      <img src={require("../images_fl/slide2usp.jpg")} className="slider-img-descp" alt="USP"/>
                      <div className="slider-ctas">
                        <a href="#alumni">Our Alumni</a>
                        <a href="#contact">Request Brochure</a>
                      </div>
                    </div>
                  </div>
                  <div className="slider-inner-col">
                    <div className="slider-img-box"><img src={require("../images_fl/2.webp")} alt="Global Standard In Digital Marketing Training"/>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- slide4 --> */}
              <div className="carousel-item">
                <div className="slider-inner">
                  <div className="slider-inner-col">
                    <div>
                      <div><img src={require("../images_fl/scholarship.jpg")} alt="Scholarships in Digital Marketing Courses"/> </div>
                      <h2>Give Wings To Your Dreams! <span style={{color:'#ffc800'}}>Scholarship Up To</span> Rs.1,00,000/-</h2>
                      <br />
                      <p style={{fontSize:'22px'}}> <strong>Get 100% assured placements</strong> with 2-month paid internships.
                      </p>
                      <br />
                      <img src={require("../images_fl/slide4usp.jpg")} className="slider-img-descp" alt="USP"/>
                      <br />
                      <div className="slider-ctas">
                        <a href="#contact">Request Brochure</a>
                      </div>
                    </div>
                  </div>
                  <div className="slider-inner-col">
                    <div className="slider-img-box"><img src={require("../images_fl/4.webp")} alt="Scholarships on Digital Marketing Courses"/>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- slide5 --> */}
              

            </div>
            <div className="carousel-controls">
              <span className="prev"></span>
              <span className="next"></span>
            </div>
            <div className="carousel-indicators"></div>
            </div>

        </div>
    );
};

export default Header;