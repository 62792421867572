import react from 'react';

const Student = ()=>{
    return(
        <div>
        <div>
            <div id="alumni" className="row third-fold">
            <div className="test-title text-center">
            <h2>OUR STUDENTS <span style={{color:'#2fa0ff'}}>THRIVE GLOBALLY</span></h2>
    </div>
    <div className='student'>
      <img className="desk-img" src={require("../images_fl/st1.webp")} alt="IIEDM Students thriving globally"/>
      <img className="mobile-img" src={require("../images_fl/st1mobile.webp")} alt="IIEDM Students thriving globally"/>
    </div>
  </div>
  </div>
            </div>
    );
};

export default Student;