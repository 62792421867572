import React from 'react'

export default function Questions() {
  return (
    <div>
        <div className="FAQ-section">
        <div className="col">
          <p className="faq-header">(FAQ's) Your Questions, Our Solutions- Explained and Simplified
        </p>
          <div className="tabs">
            <div className="tab">
              <input type="radio" id="rd7" name="rd"/>
              <label className="tab-label" for="rd7">What is content writing?</label>
              <div className="tab-content">
                <p>Content writing refers to planning, writing and editing content majorly for the digital platforms. It can involve a number of things such as writing blogs, website content, articles and content for particular platforms such as Twitter, LinkedIn, etc for marketing purposes or more. Good courses can help you get well acquainted with the different forms of writing and give you access to a plethora of opportunities in the digital marketing space.</p><br/>
                <p>IIEDM- a reputable institute in Mumbai helps you learn the A to Z of content writing that allows you to excel with every word you write.</p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd8" name="rd"/>
              <label className="tab-label" for="rd8">Who can do content writing or take up our course?</label>
              <div className="tab-content">
                <p>If words entice you, then that's enough for you to learn about content and its use in the digital marketing space. Our certification course takes you from the basics to the advanced. No matter which profession you belong to, you can learn it all.</p><br/>
                <p>At IIEDM, a prominent institute in Mumbai, we welcome everyone, even those with little or no knowledge as our content marketing course offers in-depth knowledge of writing the right way.</p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd9" name="rd"/>
              <label className="tab-label" for="rd9">What are the Career Opportunities after completing the course?</label>
              <div className="tab-content">
                <p>At IIEDM we offer one of the best creative writing courses in Mumbai that opens door to a number of opportunities for you, such as
                </p>
                <ul>
                  <li>Blogging</li>
                  <li>Freelancing</li>
                  <li>Copywriting</li>
                  <li>SEO Writing and a lot more</li>
                </ul><br/>
                <p>Being an experienced institute in Mumbai, with our course we ensure to offer you the in-depth knowledge  of the industry that opens doors to several opportunities for enthusiasts.</p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd10" name="rd"/>
              <label className="tab-label" for="rd10">Do I need to have a content background?</label>
              <div className="tab-content">
                <p>No, for joining our course you aren't required to have a content background. All you need is interest, concentration and dedication. The rest is covered by our content writing certification course.</p><br/>
                <p>At IIEDM- the finest institute in Mumbai, we offer one of the best content marketing courses that covers everything about content writing and its use in the industry. </p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd11" name="rd"/>
              <label className="tab-label" for="rd11">Why is it important to select a good content writing course?</label>
              <div className="tab-content">
                <p>There are multiple content writing courses available in Mumbai and the rest of the country, however, selecting the best is essential as a good course ensures that you get the right knowledge about the tools, techniques, strategies and practices.</p><br/>
                <p>At IIEDM- a leading institute in Mumbai, we have the best professionals and a well-curated course that helps you gain all the necessary knowledge related to content marketing.</p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd12" name="rd"/>
              <label className="tab-label" for="rd12">Where can content writing be applied?</label>
              <div className="tab-content">
                <p>There are various areas where content writing can be applied:</p>
                <ul>
                    <li>Sales, advertisement and marketing of products</li>
                    <li>SEO in digital marketing</li>
                    <li>Digital marketing and e-commerce</li>
                    <li>Creative writing</li>
                    <li>Social media marketing and more</li>
                </ul><br/>
                <p>At IIEDM, a prominent institute in Mumbai, through our course we acquaint you with the right knowledge that can be applied to every area where content is required, and that is literally everywhere.</p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd13" name="rd"/>
              <label className="tab-label" for="rd13">Do I get a completion certificate?</label>
              <div className="tab-content">
                <p>Yes, you can avail of content writing certification after you have completed the course. You can also add this certification to your resume for securing a good job opportunity especially in the digital marketing field.</p><br/>
                <p>At IIEDM, a credible institute in Mumbai, we ensure to offer you with a recognised certificate that can help you avail good opportunities.</p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd14" name="rd"/>
              <label className="tab-label" for="rd14">Does the course involve only theoretical matters?</label>
              <div className="tab-content">
                <p>No, our content marketing training course in Mumbai ensures to give you the best of both worlds i.e theory and practical. With live projects, you can get hands-on experience and via a comprehensive curriculum, you can get the right knowledge.</p><br/>
                <p>At IIEDM, a leading digital marketing institute in Mumbai, we aim to offer holistic learning that makes you industry ready.</p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd15" name="rd"/>
              <label className="tab-label" for="rd15">Does IIEDM offer placements?</label>
              <div className="tab-content">
                <p>Yes, we have 100% placements that give you the opportunity to be placed in renowned organizations in Mumbai and other cities.</p><br/>
                <p>IIEDM is a trusted institute in Mumbai that doesn’t leave your side once the course is complete, instead, we take every step for you to avail the best opportunity that can make your career.</p>
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="rd16" name="rd"/>
              <label className="tab-label" for="rd16">Why should I learn from IIEDM?</label>
              <div className="tab-content">
                <p>Our passion since our inception has always been to offer nothing but the best which has landed us the Best Digital Marketing Institute Award. Through our course, with the finest faculty we strive to offer the best practical and theoretical experience that allows you to step into the industry with experience. We also offer exciting placement opportunities.</p><br/>
                <p>IIEDM is an institute of excellence in Mumbai that strives to offer nothing but the best to students at every part of the process.</p>
              </div>
            </div>
    
          </div>
        </div>
    
    </div>
    </div>
  )
}
