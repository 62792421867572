import React from 'react'

export default function Mid() {
  return (
    <div>
        <div class="careerz">
        
        <div class="test-header">
            Hear it from the horse's mouth
            <span>No one tells a better story than an insider.Triumphantly working their way through the digital world, our alumni make us proud. Hear what our students have to say about us. </span>
            <div class="btns-hold">
                <a href="#video" class="button" title="Check out our Video Testimonials">Video Testimonials</a>
                <a href="#written" class="button" title="Review by IIEDM Students">Written Testimonials</a>
            </div>
        </div>

        <div id="video" class="test-headerz">
        <p style={{textAlign:"center"}}>Video Testimonials</p>

        </div>

        <div  class="test-boxes">
            <div class="text-con-boxz">
                <a href="https://www.youtube.com/watch?v=DOt1ZaJovVc&list=PLtbZQANk29Yqnzuzg1iaHboWg6BQL8dRH&index=5" class="test-box" data-lightbox="">
                    <img width="235" height="185" loading="lazy" src={require("../images_fl/zeus.jpg")} alt="IIEDM Student Testimonials"/>
                    <div class="test-title">
                        Zeus Balaporia
                        <span>DMCP</span>
                    </div>
                </a>
            </div>
            <div class="text-con-boxz">
                <a href="https://www.youtube.com/watch?v=vZe_hjPWNCQ&list=PLtbZQANk29Yqnzuzg1iaHboWg6BQL8dRH&index=4" class="test-box" data-lightbox="">
                    <img width="235" height="185" loading="lazy" src={require("../images_fl/rohan.jpg")} alt="IIEDM Student Testimonials"/>
                    <div class="test-title">
                        Rohan Surve
                        <span>APDM</span>
                    </div>
                </a>
            </div>
            <div class="text-con-boxz">
                <a href="https://www.youtube.com/watch?v=gFrzZ39ZZLU&list=PLtbZQANk29Yqnzuzg1iaHboWg6BQL8dRH&index=3" class="test-box" data-lightbox="">
                    <img width="235" height="185" loading="lazy" src={require("../images_fl/prerna.jpg")} alt="IIEDM Student Testimonials"/>
                    <div class="test-title">
                        Prerna Talreja
                        <span>SEO</span>
                    </div>
                </a>
            </div>
            <div class="text-con-boxz">
                <a href="https://www.youtube.com/watch?v=KAGfie6ARyA&list=PLtbZQANk29Yqnzuzg1iaHboWg6BQL8dRH&index=1" class="test-box" data-lightbox="">
                    <img width="235" height="185" loading="lazy" src={require("../images_fl/karthik.jpg")} alt="IIEDM Student Testimonials"/>
                    <div class="test-title">
                        Karthik Shrinivasan
                        <span>DMCP</span>
                    </div>
                </a>
            </div>
            <div class="text-con-boxz">
                <a href="https://www.youtube.com/watch?v=l2mdT4Nv5Ok&list=PLtbZQANk29Yqnzuzg1iaHboWg6BQL8dRH&index=2" class="test-box" data-lightbox="">
                    <img width="235" height="185" loading="lazy" src={require("../images_fl/rohan_raj.jpg")} alt="IIEDM Student Testimonials"/>
                    <div class="test-title">
                        Rohan Rajpure
                        <span>DMCP</span>
                    </div>
                </a>
            </div>
            <div class="text-con-boxz">
                <a href="https://www.youtube.com/watch?v=4L9Y_QdyTzY&list=PLtbZQANk29Yqnzuzg1iaHboWg6BQL8dRH&index=6" class="test-box" data-lightbox="">
                    <img width="235" height="185" loading="lazy" src={require("../images_fl/divya.jpg")} alt="IIEDM Student Testimonials"/>
                    <div class="test-title">
                        Divya Shetty
                        <span>DMCP</span>
                    </div>
                </a>
            </div>
            <div class="text-con-boxz">
                <a href="https://www.youtube.com/watch?v=838X3jos2ns&list=PLtbZQANk29Yqnzuzg1iaHboWg6BQL8dRH&index=16" class="test-box" data-lightbox="">
                    <img width="235" height="185" loading="lazy" src={require("../images_fl/apeksha.jpg")} alt="IIEDM Student Testimonials"/>
                    <div class="test-title">
                        Apeksha Sheth
                        <span>Social Media</span>
                    </div>
                </a>
            </div>
            <div class="text-con-boxz">
                <a href="https://www.youtube.com/watch?v=DBtM0LUrleQ&list=PLtbZQANk29Yqnzuzg1iaHboWg6BQL8dRH&index=15" class="test-box" data-lightbox="">
                    <img width="235" height="185" loading="lazy" src={require("../images_fl/sharad.jpg")} alt="IIEDM Student Testimonials"/>
                    <div class="test-title">
                        Sharad Mathur
                        <span>SEM/PPC</span>
                    </div>
                </a>
            </div>
            <div class="text-con-boxz">
                <a href="https://www.youtube.com/watch?v=tspnm28DquQ&list=PLtbZQANk29Yqnzuzg1iaHboWg6BQL8dRH&index=8" class="test-box" data-lightbox="">
                    <img width="235" height="185" loading="lazy" src={require("../images_fl/lilian.jpg")} alt="IIEDM Student Testimonials"/>
                    <div class="test-title">
                        Lilian D'souza
                        <span>APDM</span>
                    </div>
                </a>
            </div>
            <div class="text-con-boxz">
                <a href="https://www.youtube.com/watch?v=SOyQL8xGMms&list=PLtbZQANk29Yqnzuzg1iaHboWg6BQL8dRH&index=9" class="test-box" data-lightbox="">
                    <img width="235" height="185" loading="lazy" src={require("../images_fl/hussain.jpg")} alt="IIEDM Student Testimonials"/>
                    <div class="test-title">
                        Hussain
                        <span>APDM</span>
                    </div>
                </a>
            </div>
            <div class="text-con-boxz">
                <a href="https://www.youtube.com/watch?v=udGV2t3jOOU&list=PLtbZQANk29Yqnzuzg1iaHboWg6BQL8dRH&index=13" class="test-box" data-lightbox="">
                    <img width="235" height="185" loading="lazy" src={require("../images_fl/archana.jpg")} alt="IIEDM Student Testimonials"/>
                    <div class="test-title">
                        Archana Kanan
                        <span>APDM</span>
                    </div>
                </a>
            </div>
            <div class="text-con-boxz">
                <a href="https://www.youtube.com/watch?v=Xg_snJKkT-o&list=PLtbZQANk29Yqnzuzg1iaHboWg6BQL8dRH&index=7" class="test-box" data-lightbox="">
                    <img width="235" height="185" loading="lazy" src={require("../images_fl/sachi.jpg")} alt="IIEDM Student Testimonials"/>
                    <div class="test-title">
                        Sachi Kapoor
                        <span>Social Media Marketing</span>
                    </div>
                </a>
            </div>

        </div>
    </div>
    </div>
  )
}
