import React from 'react'

export default function Clients() {
  return (
    <div>
        <div className="alumini-banner">
            <p>Some Of Our Corporate Clients</p>
            <img loading="lazy" src={require("../images_fl/corporate-logos.png")} alt="IIEDM Alumini Placement Companies"/>
        </div>
    </div>
  )
}
