import React from 'react'

export default function Head() {
  return (
    <div>
        <div className="thankyou-content">
        <div className="container">
            <div className="text-con">
                <div className="ad-text">
                    <h1 style={{color: "white"}}>Certification Course In Content Writing</h1>
                    <h1 className="sub-header">Learn Content Writing from <strong>IIEDM (Awarded as the Best Digital Marketing Institute)</strong> in Mumbai providing top Content writing courses with placements.
                    </h1>
                    <ul>
                        <li>Get trained and mentored by<strong> Industry Expert Professionals</strong></li>
                        <li><strong>100% Job Assistance </strong> (Internship/Full Time)
                        </li>
                        <li>Practical training with <strong>Live Projects</strong></li>
                        <li>Learn how to secure Freelance Projects</li>
                    </ul>
                    <a href="#contact" className="blue-button">Request a Brochure</a>
                    <a href="#syllabus" className="blue-button syl">Syllabus</a>
                </div>

            </div>
            <div className="bg-img"></div>
        </div>
    </div>
    </div>
  )
}
