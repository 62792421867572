import React from 'react'

export default function Map(props) {
  return (
    <div>
        <div id="googleMap">
  <iframe src={props.link} width="100%" height="380" style={{border:0}} allowfullscreen="" loading="lazy" title='location'></iframe>
  </div>
    </div>
  )
}

