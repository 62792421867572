import react from 'react'

const Footer = ()=>{
    return(
        <div>
            <footer className="footer">
    <div className="footer-top">
      <div className="row container-fluid">
        <div className="col-f">
          <div className="row">


            <div className="col-30 head">
              <div className="logo">
                <a href="index.php" title="IIEDM Digital Marketing Institute"><img width="150" height="auto"
                    src={require("../images_fl/logo-v2.png")} alt="IIEDM Digital Marketing Institute Logo"/>
                </a>
                <p>We sow <strong>Knowledge, Nurture Potential & Brew Future</strong>.</p>
              </div>
              <div className="footer_social">
                <a target="_blank" href="https://www.google.com/partners/agency?id=4592896843"
                  title="Click on the badge to check the authenticity"><img width="220" height="auto" loading="lazy"
                    src={require("../images_fl/google-partner.webp")} alt="TBS - Google Partner Agency"/></a>
                <p style={{fontSize:'13px'}}>Click on the badge to check the authenticity.</p>
              </div>
            </div>


            <div className="col-17">
              <p className="head-link">Useful Links</p>
              <ul className="menuz">
                <li><a className="menu-item" href="/digital-marketing-training-student-testimonials.php"
                    title="IIEDM's Students' Testimonials">Testimonials</a></li>
                <li><a className="menu-item" href="/digital-marketing-corporate-training.php"
                    title="IIEDM's Inhouse Digital Marketing training for Corporates">Corporate Training</a></li>
                <li><a className="menu-item" href="/digital-marketing-training-franchise-in-education.php"
                    title="IIEDM's Educational Franchise Opportunities in India">Franchise</a></li>
                <li><a className="menu-item" href="/about-iiedm.php" title="Know more about us">About Us</a></li>
                <li><a className="menu-item" href="/blog/" title="IIEDM's Blog Section">Blog</a></li>
                <li><a className="menu-item" href="/contact-us.php" title="IIEDM's Contact Us Page">Contact Us</a></li>
              </ul>
            </div>
            <div className="col-28 links">
              <p className="head-link">PROGRAMS OFFERED</p>
              <ul className="menuz">
                <li><a href="/best-digital-marketing-courses-iiedm-tbs.php"
                    title="Digital Marketing Courses by IIEDM">Digital Marketing Courses</a></li>
                <li><a href="/ui-ux-design-course-mumbai.php" title="UI/UX courses offered by IIEDM">UI/UX Design
                    courses</a></li>
              </ul>
              <ul className="menuz sub">
                <li><a className="menu-item" href="/certification-course-in-seo-mumbai.php"
                    title="Certification course in SEO">Certification course in SEO</a></li>
                <li><a className="menu-item" href="/certification-course-in-social-media-marketing-mumbai.php"
                    title="Certification course in Social Media Marketing">Certification course in Social Media
                    Marketing</a></li>
                <li><a className="menu-item" href="/certification-course-in-ppc-adwords-sem-mumbai.php"
                    title="Certification course in PPC-Google Adwords-SEM">Certification course in PPC-Adwords-SEM</a>
                </li>
                <li><a className="menu-item" href="/certification-course-content-writing-mumbai.php"
                    title="Certification course in Content Writing">Certification course in Content Writing</a>
                </li>
              </ul>
            </div>


            <div className="col-25 add">
              <p className="head-link">Come visit us</p>
              <div className='footer-map'>
                <address><strong>Headquarter:</strong><br/>Ground Floor, Hind Services Industrial Premises Co-operative
                  Society Ltd, SVS Rd, Chandrakant Dhuru Wadi, Dadar, Mumbai, Maharashtra-400028<br/></address>
                <abbr title="Fax">
                  <strong>Mobile: </strong>
                </abbr>
                <a href="tel:+91 7738244304" title="Contact Us">7738244304</a><br/>
                <abbr title="Email Address">
                  <strong>Email: </strong>
                </abbr>
                <a href="mailto:contact@iiedm.com" title="E-Mail Us">contact@iiedm.com</a>

              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
    <div className="footer-down">
      <div className="row container-fluid">
        <div className="col-50">
          <p>Copyrights &copy; 2022 All Rights Reserved by IIEDM (a TBS venture) - Digital Marketing Institute.</p>
        </div>
        <div className="col-50 right">
          <p><a href="#" title="Legal">Legal</a> | <a href="#" title="Copyright">Copyright</a></p>
        </div>
      </div>
    </div>

    <div className="container-pt-2">
    <div className="fixedbtn">
      <a href="tel:7738244304">Call Now</a>
    </div>
    <div className="fixedbtn">
      <a href="https://api.whatsapp.com/send?phone=+917738244304&text=Hi, I would like to know more about Digital Marketing Courses offered by IIEDM."
        target="_blank">Whatsapp</a>
    </div>
  </div>
  {/* <!-- mobile footer view close --> */}

  <a id="scroll-to-top" href="#top" title="Scroll Back to Top"></a>
  </footer>
    </div>
    );
};

export default Footer;