import React from 'react'

export default function Head() {
  return (
    <div>
        <div class="thankyou uiux">
        <div class="container">
            <div class="text-con">
                <div class="ad-text">
                    <h1 style={{color:"white"}}>Certification Course in UI/UX</h1>
                    <h1 class="sub-header">Learn UI/UX from <strong>IIEDM - Awarded as the Best Digital Marketing Institute</strong> in Mumbai providing the best <strong>UI UX courses</strong> with placements.
                    </h1>
                    <ul style={{fontSize: "17px"}}>
                        <li>Get trained and mentored by<strong> Industry Expert Professionals</strong></li>
                        <li><strong>100% Job Assistance </strong> (Internship/Full Time)
                        </li>
                        <li>Practical training with <strong>Live Projects</strong></li>
                    </ul>
                    <a href="#contact" class="blue-button">Request a Brochure</a>
                    <a href="#syllabus" class="blue-button syl">Syllabus</a>
                </div>

            </div>
            <div class="bg-img"></div>
        </div>
    </div>
    </div>
  )
}
