import React from 'react'
import backgroundImg from '../images_fl/best-courses-bg.webp';

export default function CoursesComponent() {
  return (
    <div>
        <div className="best-digital-hero" style={{backgroundImage: `url(${backgroundImg})`, "background-size": "cover"}}>
            <div className="careerz" >
                <div className="courses-grid">
                    <div className="best-course">
                        <img loading="lazy" src={require("../images_fl/best-course-6.webp")} alt="Advanced Digital Marketing Certification Course"/>
                        <div className="course-title">
                            <h3>Advanced Digital Marketing Certification Course(ADMCC) - 5 months</h3>
                        </div>
                        <div className="btn">
                            <a href="#enquire">Request a Brochure</a>
                        </div>
                    </div>
                    <div className="best-course">
                        <img loading="lazy" src={require("../images_fl/best-course-2.webp")} alt= "Certificate Course in Digital Marketing"/>
                        <div className="course-title">
                            <h3>Certification Course in Digital Marketing(CCDM) - 3 months</h3>
                        </div>
                        <div className="btn">
                            <a href="#enquire">Request a Brochure</a>
                        </div>
                    </div>
                    <div className="best-course">
                        <img loading="lazy" src={require("../images_fl/best-course-3.webp")} alt="UI UX Design Courses"/>
                        <div className="course-title">
                            <h3>UI/UX Design courses - 3 months</h3>
                        </div>
                        <div className="btn">
                            <a href="/ui-ux-design-course-mumbai.php">Read More</a>
                        </div>
                    </div>
                    <div className="best-course">
                        <img loading="lazy" src={require("../images_fl/best-course-4.webp")} alt="Certification Courses in SEO"/>
                        <div className="course-title">
                            <h3>Certification course in SEO</h3>
                        </div>
                        <div className="btn">
                            <a href="/certification-course-in-seo-mumbai.php">Read More</a>
                        </div>
                    </div>
                    <div className="best-course">
                        <img loading="lazy" src={require("../images_fl/best-course-5.webp")} alt="Certification Courses in Social Media Marketing"/>
                        <div className="course-title">
                            <h3>Certification course in Social Media</h3>
                        </div>
                        <div className="btn">
                            <a href="/certification-course-in-social-media-marketing-mumbai.php">Read More</a>
                        </div>
                    </div>
                    <div className="best-course">
                        <img loading="lazy" src={require("../images_fl/best-course-1.webp")} alt="Certification Courses in PPC-Adwords-SEM"/>
                        <div className="course-title">
                            <h3>Certification course in PPC-Adwords-SEM</h3>
                        </div>
                        <div className="btn">
                            <a href="/certification-course-in-ppc-adwords-sem-mumbai.php">Read More</a>
                        </div>
                    </div>
                    <div className="best-course">
                        <img loading="lazy" src={require("../images_fl/best-course-7.webp")} alt="Certification Courses in Content Writing"/>
                        <div className="course-title">
                            <h3>Certification course in Content Writing</h3>
                        </div>
                        <div className="btn">
                            <a href="/certification-course-content-writing-mumbai.php">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
