import react from 'react'

const Testimonials = ()=>{
    return(
        <div>
            <div id="testimonials" className="row fourth-fold">
    <div className="test-title text-center">
      <p className="main-title">Testimonials</p>
      <p className="brand-style-h">Hear it from the ones who've experienced it. Get a sneak-peek into our courses and
        training in Mumbai from the 'insiders'</p>
    </div>
    <div className="testimonials">
      <div className="testimonials-box">
        <div className="video-testimonial">
          <a href="https://www.youtube.com/watch?v=RtRQ4XK-ocI" target="_blank"> <img width="240" height="370"
              className="test-btn" src={require("../images_fl/testimonial-1.webp")} alt="IIEDM Student Testimonials"/></a>
          <p className="t-name">Jyoti Anchalkar</p>
          <p className="t-title"> Trainer at eBay</p>
          <button className="test-btn blue-button"><a
              href="https://www.youtube.com/watch?v=KAGfie6ARyA&list=PLtbZQANk29Yqnzuzg1iaHboWg6BQL8dRH"
              target="_blank">All Testimonials</a>
          </button>
        </div>
      </div>
      <div className="testimonials-box">
        <div className="client-img">
          <img loading="lazy" width="150" height="146" src={require("../images_fl/testimonial-1.jpg")}
            alt="IIEDM Student Testimonials"/>
        </div>
        <div className="client-testimonial">
          <p className="t-name">Zeus Balaporia</p>
          <p className="t-title"> Sr. Brand Manager at IdeateLabs</p>
          <p>I am very happy to finish my course from the best institute in Mumbai- IIEDM. I got to learn many new
            things, whatever I learned from here I implement that in my workplace. The trainers were really good.</p>
          <p><img loading="lazy" src={require("../images_fl/5-stars.png")} alt="IIEDM rated 5 stars"/></p>
        </div>
      </div>
      <div id="corporate" className="testimonials-box">
        <a href="https://www.youtube.com/watch?v=3JvmLgG0zGI" target="_blank"><img width="240" height="460"
            loading="lazy" className="test-btn" src={require("../images_fl/testimonial-2.webp")} alt="IIEDM Student Testimonials"/></a>
      </div>

      <div id="halloffame" className="testimonials-box">
        <div className="client-testimonial">
          <p>Hall Of Fame</p>
          <img loading="lazy" width="246" height="368" src={require("../images_fl/success-stories-long.gif")} className="brand-style-h"
            alt="IIEDM Student Testimonials"/> <br/> <br/>

          <a className="blue-button" href="#contact">Request a Brochure</a>
        </div>
      </div>
    </div>
  </div>
        </div>
    );
};

export default Testimonials;