import '../App.css'
import Navbar from '../components/Navbar/Navbar';
import Header from '../components/Header_charni/Header';
import Company from '../components/Companies_charni/Companies';
import Mid from '../components/Mid/Mid';
import Course from '../components/Courses_charni/Courses';
import Testimonials from '../components/Testimonials/Testimonials';
import Media from '../components/Media/Media';
import Map from '../components/Map/Map';
import Question from '../components/Questions_charni/Questions';
import Footer from '../components/Footer/Footer';
import Call from '../components/Call/Call';
import Award from '../components/Award/Award';

function Dadar() {
  return (
    <div>
      <Navbar/>
      <Header location = "Dadar" phone="7738244304"/>
      <Company/>
      <Course location = "Dadar"/>
      <Call text1="Central Mumbai! Digital Marketing is the new calling!" text2="An opportunity like no other awaits digital marketing fanatics in Dadar, Matunga, Wadala, Prabhadevi, Chembur and areas nearby" phone="7738244304"/>
      <Mid phone="7738244304"/>
      <Award/>
      <Testimonials/>
      <Media/>
      <Map link="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15087.446320105897!2d72.8345468!3d19.0258202!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xaa29c98acf08b902!2sIIEDM%20-%20Indian%20Institute%20of%20E-Commerce%20%26%20Digital%20Marketing%20Courses!5e0!3m2!1sen!2sin!4v1623407977203!5m2!1sen!2sin"/>
      <Question location="Dadar"/>
      <Footer/>
    </div>
  );
}

export default Dadar;
