import {Routes, Route} from 'react-router-dom';
import Home from './Pages/Home';
import Charni from './Pages/Charni';
import Dadar from './Pages/Dadar';
import Malad from './Pages/Malad';
import Dombivli from './Pages/Dombivli';
import Courses from './Pages/Courses';
import CoorporateTraining from './Pages/CoorporateTraining';
import UI_UX_Course from './Pages/UI_UX_Course';
import Courses_SEO from './Pages/Courses_SEO';
import Courses_SocialMedia from './Pages/Courses_SocialMedia';
import Courses_PPC from './Pages/Courses_PPC';
import Courses_ContentWriting from './Pages/Courses_ContentWriting';
import ContactUs from './Pages/ContactUs';
import Awards from './Pages/Awards';
import AboutUs from './Pages/AboutUs';
import Testimonial from './Pages/Testimonials';
import Franchise from './Pages/Franchise';
import Careers from './Pages/Careers';

function App(){
  return(
    <>
    <Routes>
        <Route exact path='/' element={<Home/>}/>
        <Route path='/digital-marketing-training-institute-charni-road-mumbai' element={<Charni/>}/>
        <Route path='/digital-marketing-training-institute-dadar-mumbai' element={<Dadar/>}/>
        <Route path='/digital-marketing-training-institute-malad-mumbai' element={<Malad/>}/>
        <Route path='/digital-marketing-training-institute-dombivli-mumbai' element={<Dombivli/>}/>
        <Route path='/best-digital-marketing-courses-iiedm-tbs' element={<Courses/>}/>
        <Route path='/digital-marketing-corporate-training' element={<CoorporateTraining/>}/>
        <Route path='/ui-ux-design-course-mumbai' element={<UI_UX_Course/>}/>
        <Route path='/certification-course-in-seo-mumbai' element={<Courses_SEO/>}/>
        <Route path='/certification-course-in-social-media-marketing-mumbai' element={<Courses_SocialMedia/>}/>
        <Route path='/certification-course-in-ppc-adwords-sem-mumbai' element={<Courses_PPC/>}/>
        <Route path='/certification-course-content-writing-mumbai' element={<Courses_ContentWriting/>}/>
        <Route path='/contact-us' element={<ContactUs/>}/>
        <Route path='/award-winning-digital-marketing-institute' element={<Awards/>}/>
        <Route path='/about-iiedm' element={<AboutUs/>}/>
        <Route path='/digital-marketing-training-student-testimonials' element={<Testimonial/>}/>
        <Route path='/digital-marketing-training-franchise-in-education' element={<Franchise/>}/>
        <Route path='/careers' element={<Careers/>}/>
      </Routes>

      
    </>
  )
}
export default App;

