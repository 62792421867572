import React from 'react'
import backgroundImg from '../images_fl/corporate-hero-2.jpg';
export default function Mid() {
  return (
    <div>
        <div className="about-des" style={{background: "#fafafa"}}>
        <div className="about-des-box">
            <div className="about-des-img" style={{backgroundImage: `url(${backgroundImg})`}}></div>
            <div className="about-des-content">
                <h3>Amplify The Skills Of Your Team With Corporate Training Workshops in Digital Marketing From IIEDM.</h3>
                <p>Today, digital is the new normal, especially in the corporate realm. This mandates the need to not just know the A to Z of digital marketing but also upgrade the skills in part of the digital trends. IIEDM offers detailed corporate workshops for digital marketing that arm your employees with the right skills that enable them to deliver performance beyond the ordinary and garner high ROI. Through our corporate training in digital marketing, we aim to transform the corporate marketing scenario and paint a landscape of digital brilliance.</p>
                <p><strong>OUR DISTINCT APPROACH:</strong>  The corporate programs in our workshops are designed meticulously by IIEDM that encapsulates the digital aptitude and artistry in any corporate environment. The syllabus of the programs in these workshops is fabricated by the maestros of the digital industry that takes an individual into the depths of the field. The learning is amplified through hands-on training on the premise, one-on-one interaction and a plethora of ample case studies that offer clarity of the concepts in the workshops. The programs aren’t just for one, but for All- right from junior executives to top-level management, everyone can ace the digital space through IIEDM’s customized corporate training workshops.</p>
                <p><strong>THE ‘BOONS’:</strong>  IIEDMS’s basic and advanced digital training programs for corporates have transformed the marketing scenario for several corporate houses as these workshops have proven to maximize ROI and offer measurable results. The individualistic approach in the workshop programs helps an individual to Perform right, Deliver right and Gain right through the advanced skills that direct them to the course of distinction.</p>

                <div className="test-header">
                    <div className="btns-hold">
                        <a target="_blank" href="https://www.youtube.com/watch?v=8XVaIRsiRzo" className="button" title="Watch IIEDM on NDTV">IIEDM on NDTV</a>
                        <a href="#testimonials" className="button" title="Review by Clients">Testimonials</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}
