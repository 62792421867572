import React from "react";

export default function Mid2() {
  return (
    <div>
      <div className="ad-modules content-hero">
        <div className="header">
          <h3>
            An SEM and Google Ads institute in Mumbai that helps you master the
            Art and Science of Search engine Marketing!
          </h3>
          <p>
            Learn to build strategies through the finest Google Adword course
            and training in Mumbai that take your campaigns not only to the top
            of the search engine ladder but also deliver what matters the most-
            ROI
          </p>
        </div>
        <div className="module-boxes">
          <div className="apdm-box">
            <div className="title">
              <h3>
                Certification Course and Training in Google Ads and SEM in
                Mumbai that helps you define your digital careers pathway!{" "}
              </h3>
            </div>
            <div className="content">
              <p>
                <span className="accented">
                  With IIEDM's{" "}
                  <strong>
                    Google Ads certification course and training in Mumbai
                  </strong>
                  , you get an insight into the functionality and techniques
                  that turn you into the 'King' of Search Engine Marketing. We
                  take you through the chapters of excellence by giving you
                  theoretical and hands-on experience that help you understand
                  the target audience, the right way to communicate and the
                  bible of Search Engines, 'Algorithm'. We also equip you with
                  the skills through the best certification course and training
                  for you to understand and adapt to their ever-evolving nature
                  and help you strategize campaigns that are 'In Rhythm' with
                  the algorithms and the latest trends.
                </span>
              </p>
              <p>
                {" "}
                IIEDM, an Award-Winning Institute in Mumbai has been recognized
                to offer the finest{" "}
                <strong>
                  search engine marketing and Google Ads certification course
                  and training in Mumbai
                </strong>{" "}
                and all-around as it allows you to know the unknown of the SEM
                realm and not just the bytes. With faculty members that know
                every nook and corner of the digital world, we load you with the
                knowledge of the latest industry trends and different arenas of
                SEM like PPC and Google Adwords through the best certification
                training in Mumbai that gives you one of the most remarkable
                learning experiences and the opportunity to headstart your
                career with a 'Bang'.
              </p>
              <p>
                With IIEDM's{" "}
                <strong>
                  Google Ads certification course and training in Mumbai
                </strong>
                , you go through a journey that shapes you to develop all the
                essential practical skills required to transform your abilities
                into assets that you can monetize and market. At IIEDM, a
                trusted <strong>SEM institute in Mumbai</strong>, you enter as
                aspirants and leave as professionals which we ensure through our
                finest{" "}
                <strong>
                  Google Ads certification course and training in Mumbai
                </strong>
                . We also assist our students in clearing Google Certifications.
              </p>
              <p>Tags:</p>
              <h4>
                Search Engine Marketing courses in Mumbai, Best Paid Media
                Marketing courses in Mumbai, Google Adwords Certification
                courses, PPC courses with placements, Learn Google Adwords &
                Display Marketing
              </h4>
            </div>
            <div className="tags">
              <a className="blue-button" href="#contact">
                Request a Brochure
              </a>
            </div>
          </div>
          <div id="syllabus" className="modules-box">
            <div className="title">
              <p>Modules Covered</p>
            </div>
            <div className="ad-accordian">
              <div className="tabs">
                <div className="tab">
                  <input checked type="radio" id="rd1" name="rd" />
                  <label className="tab-label" for="rd1">
                    <p>Introduction To SEM & PPC</p>
                  </label>
                  <div className="tab-content">
                    <ul>
                      <li>Understanding Search Engines</li>
                      <li>How SEO & SEM differ</li>
                      <li>An Introduction to Pay Per Click Marketing</li>
                      <li>
                        Overview of Google Adwords and other search engines
                      </li>
                      <li>Terminology explanation</li>
                    </ul>
                  </div>
                </div>
                <div className="tab">
                  <input type="radio" id="rd2" name="rd" />
                  <label className="tab-label" for="rd2">
                    <p>Analysis & Targeting</p>
                  </label>
                  <div className="tab-content">
                    <ul>
                      <li>Website analysis</li>
                      <li>Competitor analysis</li>
                      <li>Setting objectives, goals & expectations</li>
                      <li>Targeting</li>
                    </ul>
                  </div>
                </div>
                <div className="tab">
                  <input type="radio" id="rd3" name="rd" />
                  <label className="tab-label" for="rd3">
                    <p>Using Google Adwords</p>
                  </label>
                  <div className="tab-content">
                    <ul>
                      <li>Fundamentals of AdWords</li>
                      <li>AdWords account setup</li>
                      <li>
                        Researching keywords with the Keyword Planner tool
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="tab">
                  <input type="radio" id="rd4" name="rd" />
                  <label className="tab-label" for="rd4">
                    <p>Campaign Management</p>
                  </label>
                  <div className="tab-content">
                    <ul>
                      <li>Setting up a campaign</li>
                      <li>Creating ad groups</li>
                      <li>Writing great ad headlines and copy</li>
                      <li>Geo targeting & local search campaigns</li>
                      <li>Bid management</li>
                      <li>Budget management</li>
                      <li>Optimizing Quality Score</li>
                    </ul>
                  </div>
                </div>
                <div className="tab">
                  <input type="radio" id="rd6" name="rd" />
                  <label className="tab-label" for="rd6">
                    <p>Effective Landing Pages</p>
                  </label>
                  <div className="tab-content">
                    <ul>
                      <li>How to Create Killer Landing Pages</li>
                      <li>Call-to-action</li>
                    </ul>
                  </div>
                </div>
                <div className="tab">
                  <input type="radio" id="rd7" name="rd" />
                  <label className="tab-label" for="rd7">
                    <p>Testing</p>
                  </label>
                  <div className="tab-content">
                    <ul>
                      <li>A/B Ad copy Testing</li>
                      <li>Testing Landing Pages</li>
                    </ul>
                  </div>
                </div>
                <div className="tab">
                  <input type="radio" id="rd8" name="rd" />
                  <label className="tab-label" for="rd8">
                    <p>Performance Tracking & Reporting</p>
                  </label>
                  <div className="tab-content">
                    <ul>
                      <li>Installing conversion tracking code</li>
                      <li>Goals & Funnels tracking</li>
                      <li>Integrate adwords account with Google Analytics</li>
                      <li>Understanding reports and Performance monitoring</li>
                      <li>Optimising performance, profitability and growth</li>
                      <li>Measuring ROI</li>
                    </ul>
                  </div>
                </div>
                <div className="tab">
                  <input type="radio" id="rd9" name="rd" />
                  <label className="tab-label" for="rd9">
                    <p>SEM Management & Tools</p>
                  </label>
                  <div className="tab-content">
                    <ul>
                      <li>AdWords tools / Adwords Editor</li>
                      <li>Google Display Network (GDN)</li>
                      <li>Creating video ad campaign</li>
                      <li>Remarketing</li>
                      <li>Managing multiple accounts</li>
                      <li>Best Practices</li>
                    </ul>
                  </div>
                </div>

                <div className="tab">
                  <input type="radio" id="rda9" name="rd" />
                  <label className="tab-label" for="rda9">
                    <p>Google Certification</p>
                  </label>
                </div>

                <div className="tab">
                  <input type="radio" id="rda10" name="rd" />
                  <label className="tab-label" for="rda10">
                    <p>Interview Preparation</p>
                  </label>
                </div>

                <div className="tab">
                  <input type="radio" id="rd10" name="rd" />
                  <label for="rd10" className="tab-close">
                    Close others &times;
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
