import React from 'react'
import image from '../images_fl/stu-testimonial-hero.jpg';

export default function Head() {
  return (
    <div>
        <div class="aboutzz-hero-con">
        <div class="aboutzz-hero">
            <div class="about-text">
                <h1> Testimonials</h1>
                <span>Hear Our Alumni Fan The Flame - Students Speak</span>
            </div>
        <div class="ab-img-box">
            <div class="imgs">
                <div class="bg-img" style={{backgroundImage: `url(${image})`}}></div>
                <div class="image-box-pattern">
                    <div class="img-pattern"></div>
                </div>
                <div class="img-circle-pattern"></div>
            </div>
        </div>
        </div>
    </div>
    </div>
  )
}
