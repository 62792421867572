import React from "react";

export default function Mid2() {
  return (
    <div>
      <div className="ad-modules content-hero">
        <div className="header">
          <h1 className="second-tile">
            The Best SEO Certification Course in Mumbai
          </h1>
          <p>
            Learn the A-Z of Search Engine Optimization and acquire skills and
            techniques through IIEDM’s comprehensive certification course and
            training in Mumbai to create your path of excellence.
          </p>
        </div>
        <div className="module-boxes">
          <div className="apdm-box">
            <div className="title">
              <h3>
                One of the top SEO Certification Courses and Training in Mumbai
                that'll help skyrocket your career!
              </h3>
            </div>
            <div className="content">
              <p>
                The{" "}
                <strong>
                  advanced Search Engine Optimization certification course and
                  training offered by IIEDM
                </strong>{" "}
                - a leading institute in Mumbai is meticulously crafted by
                industry experts that weave in their expertise, industry
                knowledge, latest trends to offer you not just a course but an
                experience. This is topped with exceptional{" "}
                <strong>Search engine Optimization training in Mumbai</strong>{" "}
                that aids you in creating compelling SEO strategies and helps
                you wear the crown of brilliance. Through elaborate years of
                experience, we provide the{" "}
                <strong>
                  best Search engine Optimization certification course and
                  training with placements
                </strong>{" "}
                at the finest places in the industry for you to become better
                than the rest.
              </p>

              <p>
                <span className="accented">
                  Our SEO certification course and training in Mumbai equips you
                  with the best arsenal- tools and techniques that help you to
                  stand strong at the digital forefront with the shield of
                  expertise.
                </span>

                <p>Tags:</p>
                <h4>
                  SEO courses in Mumbai, Best SEO courses in Mumbai, Top Search
                  Engine Optimization courses, SEO courses with placements
                </h4>
              </p>
            </div>
            <div className="tags">
              <a className="blue-button" href="#contact">
                Request a Brochure
              </a>
            </div>
          </div>
          <div id="syllabus" className="modules-box">
            <div className="title">
              <p>Modules Covered</p>
            </div>
            <div className="ad-accordian">
              <div className="tabs">
                <div className="tab">
                  <input checked type="radio" id="rd1" name="rd" />
                  <label className="tab-label" for="rd1">
                    <p>Introduction To Search Engine Optimization</p>
                  </label>
                  <div className="tab-content">
                    <ul>
                      <li> SEO fundamentals</li>
                      <li>Key terminology &amp; technology</li>
                      <li>Search engine algorithms</li>
                      <li>Major search engines &amp; directories</li>
                    </ul>
                  </div>
                </div>
                <div className="tab">
                  <input type="radio" id="rd2" name="rd" />
                  <label className="tab-label" for="rd2">
                    <p>ON Page Optimization </p>
                  </label>
                  <div className="tab-content">
                    <ul>
                      <li>SWOT analysis of website</li>
                      <li>Keyword research &amp; analysis</li>
                      <li>Competition analysis</li>
                      <li>
                        Meta tags (title, keywords, description) optimization
                      </li>
                      <li>Robots.txt optimization</li>
                      <li>Canonical tag implementation</li>
                      <li>H-tagging - Hyper link optimization</li>
                      <li>Image optimization</li>
                      <li>SEO friendly website content</li>
                      <li>301 redirect</li>
                      <li>URL rewriting</li>
                      <li>Site map creation &amp; submission</li>
                      <li>Landing page optimization</li>
                      <li>Google analytics setup</li>
                      <li>Google webmaster tools</li>
                    </ul>
                  </div>
                </div>
                <div className="tab">
                  <input type="radio" id="rd3" name="rd" />
                  <label className="tab-label" for="rd3">
                    <p>OFF Page Optimization </p>
                  </label>
                  <div className="tab-content">
                    <ul>
                      <li>Directory submission</li>
                      <li> Article submission</li>
                      <li> Forum posting</li>
                      <li>Blog commenting</li>
                      <li>Classifieds submission</li>
                      <li>Social bookmarking</li>
                      <li>Online PR</li>
                      <li>Link building</li>
                    </ul>
                  </div>
                </div>
                <div className="tab">
                  <input type="radio" id="rd4" name="rd" />
                  <label className="tab-label" for="rd4">
                    <p>Tools & Reporting</p>
                  </label>
                  <div className="tab-content">
                    <ul>
                      <li>SEO tools</li>
                      <li>SEO reporting</li>
                      <li>
                        Algorithm updates (Panda, Penguin, and Hummingbird
                        updates)
                      </li>
                      <li>Best practices</li>
                    </ul>
                  </div>
                </div>
                <div className="tab">
                  <input type="radio" id="rd5" name="rd" />
                  <label for="rd5" className="tab-close">
                    Close others &times;
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
