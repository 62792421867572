import React from 'react'

export default function Card() {
  return (
    <div>
        <div className="success-sto about">
        <div className="cards">

            <div className="card">
                <div className="con-img">
                    <img loading="lazy" src={require("../images_fl/corporate-digital-marketing-training.jpg")} alt="Digital Marketing Corporate Training Programs" title="Corporate Training in Digital Marketing"/>
                </div>
                <div className="con-details">
                    <h6>CORPORATE TRAINING</h6>
                    <p>Because the world is changing.</p>
                    <div className="card-brief">
                        <p>With the fast-paced changes happening across the globe, it is vital for any organization to invest in trainings to acquaint its employees with the emerging trends and train them for the respective skill sets. This aids the growth of organization as a whole.</p>
                    </div>
                </div>
                <div className="con-btn">
                    <a href="" className="blue-button">Read More</a>
                </div>
            </div>

            <div className="card">
                <a href="">
                    <div className="con-img">
                        <img loading="lazy" src={require("../images_fl/educational-franchise-opportunities-in-india.jpg")} alt="Educational Franchise" title="Digital Marketing Franchise in India"/>
                    </div>
                    <div className="con-details">
                        <h6>FRANCHISE</h6>
                        <p>Make the wise move.</p>
                        <div className="card-brief">
                            <p>We belong to the time that is witnessing a lateral shift in the way industries function. The gap between demand and supply of digital professionals is widening. Investing in training and supplying these techies is a future-proofed growth avenue.</p>
                        </div>
                    </div>
                </a>
                <div className="con-btn">
                    <a href="" className="blue-button">Read More</a>
                </div>
            </div>

            <div className="card">
                <a href="">
                    <div className="con-img">
                        <img loading="lazy" src={require("../images_fl/student-testimonials-on-digital-marketing-courses.jpg")} alt="Feedback on Digital Marketing Classes" title="Student Testimonials"/>
                    </div>
                    <div className="con-details">
                        <h6>TESTIMONIALS</h6>
                        <p>Hear what your clan says about us!</p>
                        <div className="card-brief">
                            <p>Being there and done that, our students have the first-hand experience of the courses offered. We believe that learners are the best critics that define our existence and work. Have a look and become a part of our team today!</p>
                        </div>
                    </div>
                </a>
                <div className="con-btn">
                    <a href="" className="blue-button">More Testimonials</a>
                </div>
            </div>

        </div>
    </div>
    </div>
  )
}
