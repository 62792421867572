import React from 'react'

export default function Mid2() {
  return (
    <div>
        <div className="careerz">

            <div className="chr-box">
                <div className="corpdmtext big">
                    <h2 className="header" style={{letterSpacing:"1px"}}>What makes us stand apart from the rest?</h2>
                    <p><img width="15" src={require("../images_fl/right-arrow-blue.png")} alt="Right Blue Arrow"/> A national award winning training institute.</p>
                    <p><img width="15" src={require("../images_fl/right-arrow-blue.png")} alt="Right Blue Arrow"/> More than a decade of being in operation.</p>
                    <p><img width="15" src={require("../images_fl/right-arrow-blue.png")} alt="Right Blue Arrow"/>  Digital Marketing Corporate Training that is provided to the best of industry standards.</p>
                    <p><img width="15" src={require("../images_fl/right-arrow-blue.png")} alt="Right Blue Arrow"/> Syllabus that is designed by the experts in the domain of digital marketing.</p>
                    <p><img width="15" src={require("../images_fl/right-arrow-blue.png")} alt="Right Blue Arrow"/> Customized course content based on the corporate requirements.</p>
                    <p><img width="15" src={require("../images_fl/right-arrow-blue.png")} alt="Right Blue Arrow"/> Training that imparts experiential learning.</p>
                    <p><img width="15" src={require("../images_fl/right-arrow-blue.png")} alt="Right Blue Arrow"/> Relevant local and global case studies.</p>
                </div>

                <div className="corpdmtext">
                    <h2 className="header" style={{letterSpacing:"1px"}}>Who can be the beneficiaries?</h2>
                    <p><img width="15" src={require("../images_fl/right-arrow-blue.png")} alt="Right Blue Arrow"/> CXOs</p>
                    <p><img width="15" src={require("../images_fl/right-arrow-blue.png")} alt="Right Blue Arrow"/> Sales and marketing team</p>
                    <p><img width="15" src={require("../images_fl/right-arrow-blue.png")} alt="Right Blue Arrow"/> Advertising and branding team</p>
                    <p><img width="15" src={require("../images_fl/right-arrow-blue.png")} alt="Right Blue Arrow"/> PR and communications team</p>
                    <p><img width="15" src={require("../images_fl/right-arrow-blue.png")} alt="Right Blue Arrow"/> Digital marketing team</p>
                    <p><img width="15" src={require("../images_fl/right-arrow-blue.png")} alt="Right Blue Arrow"/> CRM team</p>
                </div>
            </div>

        </div>
    </div>
  )
}
