import react from 'react';

const Course = ()=>{
    return(
        <div>
            <div id="courses" className="row third-fold">
    <h1>Top Digital Marketing Courses With Placements at IIEDM</h1>
    <p className="brand-style-h">Get Incredible insights and training from IIEDM institute(a TBS venture) - an Award Winning
      Digital Marketing Institute in Mumbai.</p>
    <div className="courses">
      <div className="courses-box">
        <div className="course-img">
          <img loading="lazy" src={require("../images_fl/fsdm.jpg")} alt="Digital Marketing Certificate Program"/>
        </div>
        <div className="course-detail">
          <h3>MBA - Level Post Graduate Program In Digital Marketing (PGPDM)</h3>
          <p>1 year full time Digital Marketing course with an assured package of 3-5 lakhs. One of the top Post
            Graduation Program In Digital Marketing in Mumbai that'll help skyrocket your career!</p>
          <div className="reqBr">
            <a className="blue-button" href="/mba-in-digital-marketing.html" target="_blank">Know More</a>
          </div>
        </div>
      </div>
      <div className="courses-box">
        <div className="course-img">
          <img loading="lazy" src={require("../images_fl/dmcp.jpg")} alt="Advanced Digital Marketing Certification Course"/>
        </div>
        <div className="course-detail">
          <h3>Advanced Digital Marketing Certification Course(ADMCC)- 5 months</h3>
          <p>With the Advanced Program in Digital Marketing at the finest institute- IIEDM institute, Mumbai, the focus
            is on the changing trends, best practices and advanced tools.</p>
          <div className="reqBr">
            <a className="blue-button" href="#contact">Request a Brochure</a>
          </div>
        </div>
      </div>
      <div className="courses-box">
        <div className="course-img">
          <img loading="lazy" src={require("../images_fl/best-course-7.webp")} alt="Certification Course in Digital marketing"
            height="auto"/>
        </div>
        <div className="course-detail">
          <h3>Certification Course in Digital Marketing(CCDM)- 3 months</h3>
          <p>A Certificate Program Conducted by industry experts at the leading institute- IIEDM institute, Mumbai takes
            candidates through the nook and corner of the digital world.</p>
          <div className="reqBr">
            <a className="blue-button" href="#contact">Request a Brochure</a>
          </div>
        </div>
      </div>
      <div className="courses-box">
        <div className="course-img">
          <img loading="lazy" src={require("../images_fl/uiux.jpg")} alt="Certification Course in UI/UX"/>
        </div>
        <div className="course-detail">
          <h3>Certification Course in UI/UX</h3>
          <p>Learn the current UI/UX trends across various platforms such as web and mobile applications with IIEDM
            institute- Mumbai. Get skilled in using the latest tools used in the industry.</p>
          <div className="reqBr">
            <a className="blue-button" href="#contact">Request a Brochure</a>
          </div>
        </div>
      </div>
    </div>
  </div>
        </div>
    );
};

export default Course;